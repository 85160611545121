import React from 'react';
import { CarouselProvider, Image, Slide, Slider } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

export default function ImageCarousel({
  imgsURLs,
  currentSlide
}: {
  imgsURLs: string[];
  currentSlide: number;
}) {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={imgsURLs.length}
      step={1}
      naturalSlideWidth={400}
      naturalSlideHeight={500}
      currentSlide={currentSlide}
      hasMasterSpinner>
      <Slider>
        {imgsURLs.map((imgURL, i) => (
          <Slide index={i} key={i}>
            <Image src={imgURL} hasMasterSpinner className="object-cover cursor-pointer" />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
}
