import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAllModelsBySubcategory,
  getAllSubcategories,
  getSubcategoryModelsByName
} from '../apis/subcategories';
import LoadingWidget from '../components/LoadingWidget';
import ModelCard from '../components/ModelCard';
import { divs } from '../constants/styles';
import { Category, Model } from '../models/interfaces';

import TopBarScreen from './layouts/TopBarScreen';

export default function SubcategoryScreen() {
  const { nombre } = useParams();
  const [search, setSearch] = useState('');
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [models, setModels] = useState([]);

  useEffect(() => {
    setInitialModels();
  }, []);

  useEffect(() => {
    getModelsByName();
  }, [id, search]);

  async function setInitialModels() {
    const subcategoryId = await setSubcategoryId();
    await getModelsBySubcategory(subcategoryId);
  }
  async function setSubcategoryId() {
    const subcategories = (await getAllSubcategories()) as any;
    let subcategoryId = '';
    if (isLoading) {
      subcategories.forEach((subcategory: Category) => {
        if (subcategory.name === nombre) {
          setId(subcategory.id);
          subcategoryId = subcategory.id;
        }
      });
    }
    return subcategoryId;
  }

  async function getModelsBySubcategory(id: string) {
    if (id.length > 0) {
      const models = await getAllModelsBySubcategory(id);
      setModels(models);
      setIsLoading(false);
    }
  }

  function getModelsByName() {
    if (search.length > 0 && id.length > 0) {
      getSubcategoryModelsByName(search, id).then((response) => {
        setModels(response);
      });
    } else if (search.length === 0) {
      getModelsBySubcategory(id);
    }
  }

  return (
    <TopBarScreen>
      {isLoading ? (
        <div className="h-[100px] w-[100px] relative mx-auto mt-[25%]">
          <LoadingWidget></LoadingWidget>
        </div>
      ) : id?.length > 0 ? (
        <div className="m-10">
          <p className="text-3xl font-semibold mb-6">{nombre}</p>
          <input
            className="bg-grey p-[10px] mb-10 w-full px-4 shadow-[0px_4px_5px_rgba(0,0,0,0.07)] rounded-md outline-none text-[17px]"
            placeholder="Buscar en la categoría..."
            onChange={(val) => {
              setSearch(val.target.value);
            }}
          />
          <div className={divs.grid}>
            {models?.length > 0 ? (
              models.map((model: Model) => {
                return (
                  <ModelCard
                    model={model}
                    modelId={model.model_id}
                    imgBucketPath={model.imgs_bucket_paths[0].path}
                    title={model.name}
                    brand={model.brand}
                    price={model.price}
                    key={model.model_id}></ModelCard>
                );
              })
            ) : (
              <p className="mt-2">No se encontraron resultados</p>
            )}
          </div>
        </div>
      ) : (
        <div>No existe esta subcategoría</div>
      )}
    </TopBarScreen>
  );
}
