import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getUserOrders } from '../../apis/orders';
import { divs } from '../../constants/styles';
import { Order } from '../../models/interfaces';
import { AuthContext } from '../../providers/Auth';
import { orderStatusString } from '../../utils/orderStatusConverter';
import { RandomID } from '../../utils/randomIDGenerator';
import TopBarScreen from '../layouts/TopBarScreen';

export default function OrdersScreen() {
  const { currentUser } = React.useContext(AuthContext);
  const [orders, setOrders] = React.useState<Order[]>([]);
  const navigate = useNavigate();

  async function getUserOrdersHandler() {
    const res = await getUserOrders(currentUser?.uid);
    if (res) setOrders(res);
    else {
      toast.error('Hubo un error cargando los pedidos', { position: 'top-center' });
      return;
    }
  }

  React.useEffect(() => {
    getUserOrdersHandler();
  }, []);

  return (
    <TopBarScreen backRoute="../miPerfil">
      <div className={divs.content}>
        <h1 className="text-3xl text-center my-4 font-semibold text-primary mb-6">Mis Pedidos</h1>
        <div className="px-7 mb-7">
          {orders?.map((order: Order) => {
            return OrderItem(order);
          })}
        </div>
      </div>
      <ToastContainer />
    </TopBarScreen>
  );

  function OrderItem(order: Order) {
    return (
      <div
        onClick={() => {
          navigate(`/miPerfil/pedidos/${order?.order_id}`, {
            state: { order }
          });
        }}
        key={RandomID()}
        className="border-[1px] rounded-lg mb-4 flex flex-col px-4 py-3 shadow-md">
        <div className="flex items-center mb-2">
          {/* Date */}
          <p className="text-primary text-[17px] font-semibold mr-5">
            {new Date(order?.date_created).toDateString()}
          </p>
          {/* Status */}

          <p
            className={
              'text-[14px] font-semibold text-orange-400 ' +
              (order?.status === 'pedido_cancelado' ? ' text-red-400' : ' ')
            }>
            {orderStatusString(order?.status)}
          </p>
          {order.is_in_support && (
            <p className="text-[14px] font-semibold bg-red-600 ml-4 rounded-lg px-2 text-white">
              En Soporte
            </p>
          )}
        </div>

        {/* Info */}
        <div className="flex">
          <div className="flex flex-col w-[60%]">
            {/* Status */}
            <p className="text-[13px] font-semibold text-gray-600 leading-none">Enviando a:</p>
            <p className="font-semibold">{order?.address?.street}</p>
          </div>
          <div className="flex flex-col w-[40%]">
            {/* Status */}
            <p className="text-[13px] font-semibold text-gray-600 leading-none">Total:</p>
            <p className="font-semibold">$ {order?.total_credits}</p>
          </div>
        </div>
      </div>
    );
  }
}
