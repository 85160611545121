import axios from 'axios';
import { PaymentCard, StripePaymentCard } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function getUserPaymentCards(customerMail: string) {
  try {
    const data = await axios.get(`${url}/payments/payment-methods/${customerMail}`, {});

    return data.data.payment_methods as StripePaymentCard[];
  } catch (error) {
    ManageErrorLog(error, 'Error in getUserPaymentCards');
    return false;
  }
}

interface CreatePaymentCardProps {
  card: PaymentCard;
  customerMail: string;
}
export async function createUserPaymentCard({ card, customerMail }: CreatePaymentCardProps) {
  try {
    const data = await axios.post(`${url}/payments/payment-methods/${customerMail}/new`, {
      number: card.number,
      exp_month: card.exp_month,
      exp_year: card.exp_year,
      cvc: card.cvc
    });

    return data.data.payment_method as StripePaymentCard;
  } catch (error) {
    ManageErrorLog(error, 'Error in createUserPaymentCard');
    return false;
  }
}

export async function deleteUserPaymentCard(paymentMethodId: string) {
  try {
    await axios.delete(`${url}/payments/payment-methods/delete/${paymentMethodId}`, {});

    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in deleteUserPaymentCard');
    return false;
  }
}

interface UpdatePaymentCardProps {
  paymentMethodId: string;
  address: {
    city: string;
    line1: string;
    postal_code: string;
    state: string;
  };
  tag: string;
  name: string;
}
export async function updatePaymentCard({
  paymentMethodId,
  address,
  name,
  tag
}: UpdatePaymentCardProps) {
  try {
    await axios.put(`${url}/payments/payment-methods/update/${paymentMethodId}`, {
      name: name,
      address: address,
      tag: tag
    });

    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in updatePaymentCard');
    return false;
  }
}
