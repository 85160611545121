import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function CartItem({
  item,
  removeCartItem,
  onlyView,
  available
}: {
  item: any;
  removeCartItem: (item: any) => void;
  onlyView: boolean;
  available: boolean;
}) {
  return (
    <div className="border-b border-gray-200 py-3 p-1 relative">
      <div className="flex w-full items-center">
        {/* Image */}
        <img
          src={item?.data?.model_imgs[0]?.url}
          alt="Model Image"
          className="sm:w-[13%] w-[20%] object-cover md:h-[90px] h-[80px] rounded-sm"
        />

        <div className="flex md:flex-row flex-col w-full">
          {/* Model data */}
          <div className="mx-5 ml-7 my-3 flex flex-col md:mt-3 grow">
            <Link to={`/modelo/${item?.data?.model_id}`}>
              <p className="font-semibold text-primary sm:text-xl text-[17px]">
                {item?.data?.name}
              </p>
            </Link>
            <p className="font-semibold text-yellow text-[13px]">{item?.data?.brand}</p>
            {item?.unavailable && (
              <p className="font-semibold text-red-600 text-[14px]">No disponible</p>
            )}
          </div>

          {/* Price and size */}
          <div className="mx-5 md:my-3 flex md:flex-col md:mt-3 md:w-[30%] ml-7 items-center mb-3">
            <p className="font-semibold text-gray-800 text-[15px] md:mb-1 md:mt-1 mr-5">
              {item?.data?.price} créditos
            </p>
            <p className="font-semibold text-gray-800 text-[15px]">
              Talla: <span className="ml-2">{item?.size}</span>
            </p>
            {item?.product_in_stock && (
              <div className="h-[20px]  mt-[2px] ml-1 text-center font-semibold">
                <p className="text-white  bg-green-500 rounded-lg text-sm px-1">STOCK</p>
              </div>
            )}
          </div>

          {/* Actions */}
          {!onlyView && (
            <div className="md:mx-6 my-3 flex-col justify-center flex absolute md:static top-5 right-2">
              <FontAwesomeIcon
                className="text-red/80 cursor-pointer"
                icon={faTrash}
                onClick={() => removeCartItem(item)}
              />
            </div>
          )}
        </div>
        {/* Product Not available */}
        {!available && (
          <div className="bg-red-100 rounded mx-8 mt-1">
            <p className="text-center text-red-800">Este producto ya no se encuentra disponible</p>
          </div>
        )}
      </div>
    </div>
  );
}
