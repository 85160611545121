import React from 'react';

export default function Footer() {
  return (
    <div className="w-full bg-[#f3f3f3] flex justify-center mt-20 border">
      <div className="w-full max-w-[1100px] px-10 flex md:flex-row flex-col">
        {/* Left side */}
        <div className="md:w-[40%] w-full pb-14 flex-col flex md:items-start items-center">
          <img src="/imgs/logo_green.png" className="w-[160px]" />
          <h4 className="text-gray-500 -mt-10 text-[16px] md:w-[70%] leading-5 md:text-start text-center">
            Somos el inicio de una moda transparente, sustentable, justa.
          </h4>
        </div>

        {/* Right side */}
        <div className="md:w-[60%] w-full flex items-center md:justify-start justify-center h-[100%] md:pb-0 pb-10">
          <div className="md:w-full w-[90%]">
            <h4 className="font-[500] text-[20px] mt-5 mb-2 text-primary/90">Secciones</h4>
            <div className="md:flex-row flex flex-col text-[16px] mb-3">
              <div className="text-gray-800">
                <a href="/">
                  <p className="md:mb-1 mb-2">Inicio</p>
                </a>
                <a href="/#como_funciona">
                  <p className="md:mb-1 mb-2">Como Funciona</p>
                </a>
                <a href="/nosotros">
                  <p className="md:mb-1 mb-2">Nosotros</p>
                </a>
                <a href="/contacto">
                  <p className="md:mb-1 mb-2">Contacto</p>
                </a>
              </div>
              <div className="text-gray-800 md:ml-[80px]">
                <a href="/marcas">
                  <p className="md:mb-1 mb-2">Marcas</p>
                </a>
                <a href="/preguntas-frecuentes">
                  <p className="md:mb-1 mb-2">Preguntas Frecuentes</p>
                </a>
                <a href="/miPerfil/membresias">
                  <p className="md:mb-1 mb-2">Planes Mensuales</p>
                </a>
              </div>
              <div className="text-gray-800 md:ml-[80px]">
                <a href="/politica-privacidad">
                  <p className="md:mb-1 mb-2">Política de Privacidad</p>
                </a>
                <a href="/terminos-condiciones">
                  <p className="md:mb-1 mb-2">Términos y Condiciones</p>
                </a>
                <a href="/politica-cookies">
                  <p className="md:mb-1 mb-2">Pólitica de Cookies (UE)</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
