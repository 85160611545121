import { useEffect, useState } from 'react';
import { Model } from '../../models/interfaces';
import { RandomID } from '../../utils/randomIDGenerator';
import { AlertDialog } from '../../components/AlertDialog';

interface ModelInfoProps {
  model: Model | null;
  subcategories: Map<string, string> | undefined;
  category: string;
  sizesKeys: string[];
}

export default function ModelInfoView({
  model,
  sizesKeys,
  subcategories,
  category
}: ModelInfoProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  function handleOpenDialog() {
    return (
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="¿Qué Significa S?"
        description='El símbolo S proviene de "stock". Esto significa que la talla está disponible en el almacen y puede ser enviado inmediatamente.'
        agreeAction={() => {}}
        agreeText="¡Entendido!"
        agreeColor="primary"
        extraContent={<div></div>}></AlertDialog>
    );
  }

  return (
    <div className="shadow-[0_5px_15px_#e1e1e1] p-10 mx-8 md:w-[50%] md:mr-16">
      {handleOpenDialog()}
      <p className="text-3xl font-semibold mb-3">{model?.name}</p>
      <p className="text-xl text-red mb-5">{model?.brand}</p>
      <div className="flex mb-5 items-end">
        <p className="text-2xl text-primary mr-8">{model?.price} créditos</p>
        {model?.euros && <p className="text-2xl text-gray-500">€ {model?.euros} euros</p>}
      </div>
      <p className="text-[14px] text-gray-600 mb-5">{model?.description}</p>
      <div className="flex flex-row">
        <p className="text-lg font-semibold text-primary">Categoría:</p>
        <p className=" ml-2 text-lg">{category}</p>
      </div>
      <p className="text-lg font-semibold text-primary mt-4 mb-2">Subcategorías:</p>
      {model?.subcategories.map((subcategory: string) => {
        return (
          <p className="ml-8" key={RandomID()}>
            - {subcategories?.get(subcategory)}
          </p>
        );
      })}
      <div className="flex flex-row">
        <p className="text-lg font-semibold text-primary mt-4 mb-2 mr-2">Tallas:</p>

        {sizesKeys.map((sizeKey: string, i) => {
          return (
            <div className="flex" key={RandomID()}>
              <p className="text-lg mt-4 ml-2">{sizeKey}</p>
              <div className="h-[20px] w-[20px]  mt-5 ml-1 text-center ">
                <p className="text-white  bg-green-500 rounded-lg text-sm">S</p>
              </div>
              <p className="text-lg mt-4 ml-1">
                {i !== sizesKeys.length - 1 ||
                (model?.sizes_in_brand_store?.length != undefined &&
                  model?.sizes_in_brand_store?.length > 0)
                  ? ','
                  : ''}
              </p>
            </div>
          );
        })}
        {model?.sizes_in_brand_store?.map((sizeKey: string, i) => {
          if (!sizesKeys.includes(sizeKey)) {
            return (
              <div className="flex" key={RandomID()}>
                <p className="text-lg mt-4 ml-2">
                  {sizeKey}
                  {i !== sizesKeys.length - 1 && ','}
                </p>
              </div>
            );
          }
        })}
      </div>
      <div
        className="flex flex-row mt-4 cursor-pointer"
        onClick={() => {
          setOpenDialog(true);
        }}>
        <p className="text-primary">¿Qué significa </p>
        <div className="h-[20px] w-[20px] mt-[2px] ml-1 text-center ">
          <p className="text-white  bg-green-500 rounded-lg text-sm">S</p>
        </div>
        <p className="text-primary">?</p>
      </div>
    </div>
  );
}
