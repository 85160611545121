import { Button } from '@mui/material';
import { buttonsActions } from '../screens/profile/MembershipScreen';
import React, { useContext } from 'react';
import { AuthContext } from '../providers/Auth';
import { Link } from 'react-router-dom';

export default function MembershipButtons({
  isActive,

  // currentSubscription,

  openDialogHandler
}: {
  isActive: boolean;
  currentSubscription: any;

  openDialogHandler: (action: number) => void;
}) {
  const { currentUserData, currentUser } = useContext(AuthContext);

  // If this is the current or preordered membership, show cancel button
  if (isActive) {
    return (
      <Button
        color="error"
        variant="outlined"
        onClick={() => openDialogHandler(buttonsActions.cancelSubscription)}>
        Cancelar Membresía
      </Button>
    );
  }
  // If there isn't a current nor future subscription, show buy current
  else {
    return currentUserData && currentUser ? (
      <Button
        color="success"
        variant="contained"
        sx={{ mb: 2 }}
        onClick={() => openDialogHandler(buttonsActions.buySubscription)}>
        Contratar Membresía
      </Button>
    ) : (
      <Link to="/miPerfil">
        <Button color="success" variant="contained" sx={{ mb: 2 }}>
          Contratar Membresía
        </Button>
      </Link>
    );
  }
}
