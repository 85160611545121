import { useContext, useEffect, useState } from 'react';
import { divs, texts } from '../../constants/styles';
import { Model } from '../../models/interfaces';
import { AuthContext } from '../../providers/Auth';
import TopBarScreen from '../layouts/TopBarScreen';
import { getModelByName } from '../../apis/models';
import LoadingScreen from '../../components/Views/LoadingScreen';
import { RandomID } from '../../utils/randomIDGenerator';
import ModelCard from '../../components/ModelCard';

export default function FavoritesScreen() {
  const { currentUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [favorites, setFavorites] = useState<Model[]>([]);

  useEffect(() => {
    getFavorites().then((e) => {
      setFavorites(e);
      setLoading(false);
    });
  }, []);

  async function getFavorites() {
    const favoritesD = currentUserData.favorites_ids;
    const favsHolder: Model[] = [];
    for await (const id of favoritesD) {
      const model = await getModelByName(id);
      if (model) {
        favsHolder.push(model);
      }
    }
    return favsHolder;
  }

  return (
    <TopBarScreen backRoute="../miPerfil">
      {loading ? (
        <LoadingScreen></LoadingScreen>
      ) : (
        <div className={divs.content + ' lg:max-w-[1000px]'}>
          <h1 className={texts.title}>Favoritos</h1>
          {favorites.length > 0 ? (
            <div className={divs.grid}>
              {favorites.map((model) => {
                return (
                  <ModelCard
                    imgBucketPath={model.imgs_bucket_paths[0].path}
                    modelId={model.model_id}
                    price={model.price}
                    brand={model.brand}
                    title={model.name}
                    key={RandomID()}
                    model={model}></ModelCard>
                );
              })}
            </div>
          ) : (
            <p>No tienes favoritos</p>
          )}
        </div>
      )}
    </TopBarScreen>
  );
}
