export function orderStatusString(status: string) {
  switch (status) {
    case 'assigning_worker':
      return 'Confirmando';

    case 'preparing_order':
      return 'Confirmando';

    case 'order_shipped':
      return 'En Camino';
    case 'order_with_client':
      return 'Entregado';

    case 'preparing_devolution':
      return 'Devolviendo';

    case 'returning_order':
      return 'Devuelto';

    case 'order_in_store':
      return 'Devuelto';

    case 'order_canceled':
      return 'Cancelado';

    default:
      '-';
  }

  return '';
}
