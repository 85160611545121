import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buttons, texts } from '../../constants/styles';
import 'react-dropdown/style.css';
import { Model, ModelInCart } from '../../models/interfaces';
import { useContext, useEffect, useState } from 'react';
import { ContactUsProps, contactUs, updateUser } from '../../apis/users';
import { AuthContext } from '../../providers/Auth';
import { toast, ToastContainer } from 'react-toastify';
import { isEmailInAwaitingList, putEmailOnAwaitList } from '../../apis/models';
import { Select, MenuItem, Button, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AlertDialog } from '../../components/AlertDialog';

export default function ModelButtonsView({ model }: { model: Model }) {
  const { currentUser, currentUserData } = useContext(AuthContext);
  const [options, setOptions] = useState<string[]>([]);

  const [selectedSize, setSelectedSize] = useState<string>('');
  const [productInCart, setProductInCart] = useState<boolean>(false);

  const [isFavorite, setIsFavorite] = useState<boolean>();

  const [isAwaiting, setIsAwaiting] = useState<boolean>(true);

  //------ Buy Product
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [contactType, setContactType] = useState<string>('Mensaje de texto');
  const [contactValue, setContactValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (currentUser) {
      isEmailInAwaitingList(model.model_id, currentUserData?.email, currentUser.uid).then((e) => {
        setIsAwaiting(e);
      });
    } else {
      setIsAwaiting(false);
    }
  }, []);

  async function SendSupportData() {
    const data = {
      contact_method: contactType,
      contact_value: contactValue,
      message: message,
      type: 'buy_clothe'
    } as ContactUsProps;
    contactUs(data).then((e) => {
      if (e) {
        toast.success(
          'Enviaste tus datos correctamente, pronto nos pondremos en contacto contigo.',
          { position: 'top-center' }
        );
        setContactType('Mensaje de texto');
        setContactValue('');
        setMessage('');
      } else {
        toast.error('Ocurrió un error al enviar tus datos, intenta de nuevo más tarde.', {
          position: 'top-center'
        });
      }
    });
  }

  function ContactUsDialog() {
    return (
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Contactanos"
        description="Para comprar este producto, es necesario que te cominuques con nosotros."
        agreeAction={() => {
          if (message.length > 1 || contactValue.length > 1) SendSupportData();
        }}
        agreeText="Contactenme"
        disagreeText="Cancelar"
        agreeColor="primary"
        disagreeColor="text-black"
        extraContent={
          <div>
            <div className=" py-6 text-center">
              <p className=" pt-6 pb-4">¿Cómo quieres que te contactemos?</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contactType}
                label="Age"
                onChange={(e) => {
                  setContactType(e.target.value);
                }}>
                <MenuItem value={'Mensaje de texto'}>Mensaje de texto</MenuItem>
                <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem>
                <MenuItem value={'Llamada telefónica'}>Llamada telefónica</MenuItem>
                <MenuItem value={'Email'}>Correo electrónico</MenuItem>
              </Select>
            </div>

            <div className=" py-6  text-center  ">
              <p className="pb-4">Ingresa el método de contacto</p>
              <input
                value={contactValue}
                onChange={(e) => {
                  setContactValue(e.target.value);
                }}
                className="bg-grey px-4 py-2 shadow rounded w-[300px]"></input>
            </div>
            <div className=" py-6 text-center">
              <p className="pb-4">Mensaje de Contacto</p>
              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="bg-grey px-4 py-2 shadow rounded w-[300px] max-h-[300px] min-h-[150px]"></textarea>
            </div>
          </div>
        }></AlertDialog>
    );
  }

  //-------

  //Add to cart Funciton
  function addTocart() {
    const cart = currentUserData?.cart;
    const productForCart: ModelInCart = {
      model_id: model.model_id,
      size: selectedSize
    };
    const newCart = cart ? [...cart, productForCart] : [productForCart];

    updateUser({ user: currentUserData, id: currentUser.uid, cart: newCart }).then((e) => {
      if (e) {
        toast.success('Producto añadido al carrito', { position: 'top-center' });
        currentUserData.cart = newCart;
        setProductInCart(true);
      } else {
        toast.error('Ha ocurrido un error al agregar el producto a tu carrito', {
          position: 'top-center'
        });
      }
    });
  }

  function addTofavorites() {
    const favorites = currentUserData?.favorites_ids;
    let newFavorites = favorites ? [...favorites, model.model_id] : [model.model_id];

    //Because of the async nature of the firebase and react state, we need to remove duplicates when adding to favorites
    newFavorites = newFavorites.filter((e, i) => newFavorites.indexOf(e) === i);

    updateUser({ user: currentUserData, id: currentUser.uid, favorites_ids: newFavorites }).then(
      (e) => {
        if (e) {
          toast.success('Producto añadido a favoritos', { position: 'top-center' });
          currentUserData.favorites = newFavorites;
          setIsFavorite(true);
        } else {
          toast.error('Ha ocurrido un error al agregar el producto a tus favoritos', {
            position: 'top-center'
          });
        }
      }
    );
  }
  function removeFromFavorites() {
    const favorites = currentUserData?.favorites_ids;
    const newFavorites = favorites?.filter((e: string) => e !== model.model_id);

    updateUser({ user: currentUserData, id: currentUser.uid, favorites_ids: newFavorites }).then(
      (e) => {
        if (e) {
          toast.success('Producto eliminado de favoritos', { position: 'top-center' });
          currentUserData.favorites = newFavorites;
          setIsFavorite(false);
        } else {
          toast.error('Ha ocurrido un error al eliminar el producto de tus favoritos', {
            position: 'top-center'
          });
        }
      }
    );
  }
  async function addToAwaitList() {
    putEmailOnAwaitList(model.model_id, currentUserData?.email, currentUser?.uid).then((e) => {
      if (e) {
        toast.success('Te notificaremos por email cuando este producto esté disponible', {
          position: 'top-center'
        });
        setIsAwaiting(true);
      } else {
        toast.error('Ha ocurrido un error al añadirte a la lista de espera', {
          position: 'top-center'
        });
      }
    });
  }

  //See changes in the model to update the size options
  useEffect(() => {
    if (model.sizes) {
      const sizesMap = new Map<string, number>(Object.entries(model.sizes));
      const sizesKeyHolder: string[] = [];

      sizesMap.forEach((v, k) => {
        if (Number(v) >= 1) {
          sizesKeyHolder.push(k);
        }
      });

      model.sizes_in_brand_store?.forEach((element) => {
        if (!sizesKeyHolder.includes(element)) {
          sizesKeyHolder.push(element);
        }
      });

      setOptions(sizesKeyHolder);
      setSelectedSize(sizesKeyHolder[0]);

      // Set the Buy Message depending on the size selected
      const message =
        'Hola, me interesa comprar el producto: ' +
        model.name +
        ' en la talla: ' +
        selectedSize +
        '.';
      setMessage(message);
    }
  }, [model]);

  //See changes in the size change to verify if it is in the cart
  useEffect(() => {
    const cart = currentUserData?.cart;

    // Wait to update the cart
    if (cart) {
      // This variable is used to control the flow when 2 sizes are in the cart
      let isInCart = false;
      cart?.forEach((element: ModelInCart) => {
        if (element.model_id === model.model_id && !isInCart) {
          if (element.size === selectedSize) {
            isInCart = true;

            setProductInCart(true);
          } else {
            setProductInCart(false);
          }
        }
      });
    }
    const message =
      'Hola, me interesa comprar el producto: ' +
      model.name +
      ' en la talla: ' +
      selectedSize +
      '.';
    setMessage(message);
  }, [currentUserData, selectedSize]);

  useEffect(() => {
    const favorites = currentUserData?.favorites_ids;
    if (favorites) {
      favorites.forEach((e: string) => {
        if (e === model.model_id) {
          setIsFavorite(true);
        }
      });
    }
  }, [currentUserData]);

  if (options.length === 0) {
    return (
      <div className="w-full">
        <ToastContainer></ToastContainer>
        <div className="bg-orange-400 m-4 w-full text-center p-4">
          <p className="text-white text-xl font-semibold">
            Por el momento, este producto no se encuentra disponible
          </p>
        </div>
        <div className="flex text-center  justify-center">
          {isFavorite ? (
            <>
              <FontAwesomeIcon
                onClick={() => {
                  removeFromFavorites();
                }}
                className={'text-4xl  text-red-600'}
                icon={faHeart}></FontAwesomeIcon>
              <p className="mt-1 ml-1">Eliminar de mis favoritos</p>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                onClick={() => {
                  addTofavorites();
                }}
                className={'text-4xl  text-gray-500'}
                icon={faHeart}></FontAwesomeIcon>
              <p className="mt-1 ml-1">Agregar a mis favoritos</p>
            </>
          )}
          {isAwaiting ? (
            <div className="pl-6 min-w-[300px] w-[20%]">
              <button disabled className={buttons.disabledButton}>
                <p className={texts.primaryButonText}>Ya estas en la lista de espera</p>
              </button>
            </div>
          ) : (
            <div className="pl-6 min-w-[300px] w-[20%]">
              <button
                onClick={() => {
                  addToAwaitList();
                }}
                className={buttons.primaryButton}>
                <p className={texts.primaryButonText}>Notificame cuando esté disponible</p>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full p-8 flex justify-end">
        {ContactUsDialog()}
        <ToastContainer></ToastContainer>
        <div className="md:w-[52%] w-full flex md:items-center sm:items-start">
          <div className="md:flex-row flex-col items-center mr-10">
            <Select
              sx={{ width: '130px', mr: 3 }}
              className="mb-4 md:mb-0"
              value={selectedSize}
              size="small"
              onChange={(e) => {
                setSelectedSize(e.target.value);
              }}>
              {options.map((size) => (
                <MenuItem value={size} key={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>

            {productInCart ? (
              <Button
                variant="contained"
                disabled
                startIcon={<ShoppingCartIcon />}
                sx={{ mr: 3 }}
                className="w-full md:w-auto">
                Modelo en Carrito
              </Button>
            ) : (
              <Button
                className="w-full md:w-auto"
                variant="contained"
                startIcon={<ShoppingCartIcon />}
                sx={{ mr: 3 }}
                onClick={() => {
                  addTocart();
                }}>
                Agregar al Carrito
              </Button>
            )}
          </div>

          {isFavorite ? (
            <IconButton
              size="large"
              onClick={() => {
                removeFromFavorites();
              }}>
              <FavoriteIcon fontSize="large" color="error" />
            </IconButton>
          ) : (
            <IconButton
              size="large"
              onClick={() => {
                addTofavorites();
              }}>
              <FavoriteBorderIcon fontSize="large" />
            </IconButton>
          )}
        </div>
        {options.length > 0 && (
          <div className="items-center justify-center text-center mt-3 text-primary">
            <button
              className="w-full md:w-auto mx-4"
              onClick={() => {
                setOpenDialog(true);
              }}>
              ¿Quieres Comprar la prenda?
            </button>
          </div>
        )}
      </div>
    );
  }
}
