import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useNavigate } from 'react-router-dom';

import { getCategories, getModelsByCategory } from '../apis/categories';
import { getModels, getModelsByName } from '../apis/models';
import LoadingWidget from '../components/LoadingWidget';
import ModelCard from '../components/ModelCard';
import { divs } from '../constants/styles';
import { Category, Model } from '../models/interfaces';
import { RandomID } from '../utils/randomIDGenerator';
import DashboardAndTopbarScreen from './layouts/DashboardAndTopbarScreen';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';

export default function ProductsScreen() {
  const [models, setModels] = useState<Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategories, setSubcategories] = useState<Map<string, Model[]>>();
  const [subCategoriesNames, setSubCategoriesNames] = useState<string[]>([]);

  const [moreModelsInPagination, setMoreModelsInPagination] = useState(true);
  const navigate = useNavigate();

  const totalModelsPerLoad = 5;
  //Get all the cateogries
  useEffect(() => {
    getCategories().then((categoriesResponse) => {
      setCategories(categoriesResponse);
    });
  }, []);

  // Get all models by search, or all models if search is empty
  useEffect(() => {
    //if the search value has one or more character, search
    if (search.length > 0) {
      setMoreModelsInPagination(false);
      getModelsByName(search).then((modelsResponse) => {
        setModels(modelsResponse);
      });
    } else {
      setMoreModelsInPagination(true);
      getModels(totalModelsPerLoad).then((modelsResponse) => {
        setModels(modelsResponse);
        setLoading(false);
      });
    }
  }, [search]);

  // Get all categories depending on the selected category
  useEffect(() => {
    //If the selected category is empty, get all the models. If not, search by category
    if (selectedCategory.length > 0) {
      setMoreModelsInPagination(false);
      getModelsByCategory(selectedCategory).then((modelsResponse) => {
        const modelsResponseMap = modelsResponse.models;
        const newMap = new Map<string, Model[]>();
        const newSubCategoriesNames: string[] = [];
        for (const modelKeys in modelsResponseMap) {
          newMap.set(modelKeys, modelsResponseMap[modelKeys]);
          newSubCategoriesNames.push(modelKeys);
        }
        setSubcategories(newMap);
        setSubCategoriesNames(newSubCategoriesNames);
      });
    } else {
      setMoreModelsInPagination(true);
      getModels(totalModelsPerLoad).then((modelsResponse) => {
        setModels(modelsResponse);
      });
    }
  }, [selectedCategory]);

  // Check if there is at least uno more model to show when the user clicks the button
  useEffect(() => {
    if (search.length <= 0 && selectedCategory.length <= 0 && models.length > 0) {
      getModels(1, models[models.length - 1].name, true).then((modelsResponse) => {
        if (modelsResponse.length === 0) {
          setMoreModelsInPagination(false);
        }
      });
    }
  }, [models]);

  return (
    <DashboardAndTopbarScreen screen="home">
      {loading ? (
        <div className="h-[300px] w-[100px] relative mx-auto my-[30%]">
          <LoadingWidget></LoadingWidget>
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <input
            //If a category is selected, the user cannot search for a model
            disabled={selectedCategory.length > 0}
            className="bg-grey m-10 p-[10px] px-4 shadow-[0px_4px_5px_rgba(0,0,0,0.07)] rounded-md outline-none text-[17px]"
            placeholder="Buscar modelo..."
            onChange={(val) => {
              setSearch(val.target.value);
            }}
          />

          <div className="mx-10">
            <ScrollMenu>
              {categories.map((category: Category) => {
                return (
                  <CategoryCard
                    id={category.id}
                    setSelected={setSelectedCategory}
                    isSelected={selectedCategory === category.id}
                    name={category.name}
                    key={RandomID()}></CategoryCard>
                );
              })}
            </ScrollMenu>
            {
              //If a category is selected, show the subcategories, if not, show all the models
            }
            {selectedCategory.length <= 0 ? (
              <div className="w-full mt-5">
                <div className={divs.grid}>
                  {models.length > 0 ? (
                    models.map((model: Model) => {
                      return (
                        <ModelCard
                          model={model}
                          modelId={model.model_id}
                          imgBucketPath={
                            model.imgs_bucket_paths[0] !== undefined
                              ? model.imgs_bucket_paths[0].path
                              : ''
                          }
                          title={model.name}
                          brand={model.brand}
                          price={model.price}
                          key={RandomID()}></ModelCard>
                      );
                    })
                  ) : (
                    <p>No se encontraron resultados</p>
                  )}
                </div>
              </div>
            ) : (
              subCategoriesNames.map((subCategoryName) => {
                const handleClick = () => navigate('/subcategoria/' + subCategoryName);
                return (
                  <div key={RandomID()}>
                    <div
                      className="flex flex-row items-center mt-10 mb-5 justify-between cursor-pointer"
                      onClick={handleClick}>
                      <p className="text-[25px] font-semibold text-gray-800" key={RandomID()}>
                        {subCategoryName}
                      </p>
                      <IconButton color="primary">
                        <ArrowForwardIcon />
                      </IconButton>
                    </div>
                    <div className={divs.grid}>
                      {subcategories?.get(subCategoryName)?.map((model: Model) => {
                        return (
                          <ModelCard
                            model={model}
                            modelId={model.model_id}
                            key={RandomID()}
                            title={model.name}
                            brand={model.brand}
                            price={model.price}
                            imgBucketPath={model.imgs_bucket_paths[0].path}></ModelCard>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {moreModelsInPagination && (
            <div className="flex justify-center mb-12">
              <button
                onClick={() => {
                  getModels(totalModelsPerLoad, models[models.length - 1].name, true).then(
                    (modelsResponse) => {
                      setModels([...models, ...modelsResponse]);
                    }
                  );
                }}
                className={
                  'text-primary mt-8 text-[17px] cursor-pointer border border-primary py-1 px-4 pb-[7px] rounded-lg hover:bg-primary/10'
                }>
                Cargar más
              </button>
            </div>
          )}
        </div>
      )}
    </DashboardAndTopbarScreen>
  );
}

interface CategoryCardProps {
  name: string;
  isSelected: boolean;
  setSelected: Dispatch<SetStateAction<string>>;
  id: string;
}
function CategoryCard({ name, isSelected, setSelected, id }: CategoryCardProps) {
  const selectedStyle = 'bg-primary text-white border-[1px]';
  const unselectedStyle = 'bg-white text-primary border-primary border-[1px] text-black';

  return (
    <div
      onClick={() => {
        if (isSelected) {
          setSelected('');
        } else {
          setSelected(id);
        }
      }}
      className={
        (isSelected ? selectedStyle : unselectedStyle) +
        ' text-[15px] text-white py-2 px-4 mr-4 rounded-lg cursor-pointer hover:bg-primary/10 transition-all duration-100'
      }>
      {name}
    </div>
  );
}
