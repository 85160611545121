import axios from 'axios';
import { Address } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export interface CreateOrderProps {
  address: Address;
  total_credits: number;
  user_id: string;
  products: any[];
}

export async function createOrder({ address, total_credits, user_id, products }: CreateOrderProps) {
  try {
    await axios.post(`${url}/orders/create`, {
      address,
      support_data: null,
      total_credits,
      user_id,
      products
    });
    return { success: true };
  } catch (error) {
    ManageErrorLog(error, 'Error in createOrder');
    return {
      success: false,
      // @ts-ignore
      msg: error?.response?.data?.msg,
      // @ts-ignore
      unavailable_products: error?.response?.data?.unavailable_products
    };
  }
}

export async function getUserOrders(userId: string) {
  // Try getting the data.
  try {
    const response = await axios.get(`${url}/orders/customers?user_id=${userId}`);
    return response?.data?.orders;
  } catch (error) {
    ManageErrorLog(error, 'Error in getUserOrders');
    return false;
  }
}

export interface OrderSupport {
  order_id: string;
  support_data: SupportData;
}
interface SupportData {
  message: string;
  contact_method: string;
  contact_value: string;
  created_by: string;
}

export async function setOrderInSupport(order: OrderSupport) {
  // Try getting the data.
  try {
    await axios.put(`${url}/orders/update-support-data`, order);
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in setOrderInSupport');
    return false;
  }
}

export async function getOrderById(id: string) {
  try {
    const response = await axios.get(`${url}/orders/get-by-id/${id}`);
    return response.data.order;
  } catch (error) {
    ManageErrorLog(error, 'Error in getOrderById');
    return false;
  }
}
