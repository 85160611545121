import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getAllSubscriptions, getUserActiveSubscriptions } from '../../apis/subscriptions';
import LoadingScreen from '../../components/Views/LoadingScreen';
import { divs, texts } from '../../constants/styles';
import { Subscription, UserSubscription } from '../../models/interfaces';
import { AuthContext } from '../../providers/Auth';
import { RandomID } from '../../utils/randomIDGenerator';
import TopBarScreen from '../layouts/TopBarScreen';

export default function MembershipsScreen() {
  const { currentUser } = useContext(AuthContext);

  const [allSubscriptions, setAllSubscriptions] = useState<Subscription[]>([]);
  const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>(null);
  const [futureSubscription, setFutureSubscription] = useState<Subscription | null>(null);

  useState<UserSubscription>();
  const [loading, setLoading] = useState(true);

  async function getSubscriptions() {
    return getAllSubscriptions().then((res) => {
      return res;
    });
  }

  const navigate = useNavigate();

  async function getUserSubscriptions(subscriptionsResponse: Subscription[]) {
    getUserActiveSubscriptions(currentUser?.uid).then((res) => {
      if (res) {
        res.forEach((subscription) => {
          const sub = subscriptionsResponse.find((s) => s.id === subscription.subscription_id);

          if (subscription.status === 'active') {
            if (sub) {
              sub.userSubscription = subscription;

              setCurrentSubscription(sub);
            }
          } else if (subscription.status === 'preordered') {
            if (sub) {
              sub.userSubscription = subscription;

              setFutureSubscription(sub);
            }
          }
        });
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    getSubscriptions().then((subscriptionsResponse) => {
      if (subscriptionsResponse) {
        setAllSubscriptions(subscriptionsResponse);
        getUserSubscriptions(subscriptionsResponse);
      }
    });
  }, []);

  return (
    <TopBarScreen backRoute="../miPerfil">
      {loading ? (
        <LoadingScreen></LoadingScreen>
      ) : (
        <div className={divs.content}>
          <h1 className={texts.title}>Membresías</h1>

          {currentSubscription && (
            <div>
              <h1 className="text-xl font-semibold ml-8 mt-8">Subcripción Actual:</h1>
              <MembershipCard active subscription={currentSubscription}></MembershipCard>
            </div>
          )}
          {futureSubscription && (
            <div>
              <h1 className="text-xl font-semibold ml-8 mt-8">Subcripción pre-ordenada:</h1>
              <MembershipCard active subscription={futureSubscription}></MembershipCard>
            </div>
          )}
          {allSubscriptions.length > 0 ? (
            <div>
              <h1 className="text-xl font-semibold ml-8 mt-8">Todas las subcsripciones:</h1>
              {allSubscriptions.map((sub) => {
                if (sub.id === currentSubscription?.id || sub.id === futureSubscription?.id)
                  return null;
                return <MembershipCard key={RandomID()} subscription={sub} />;
              })}
            </div>
          ) : (
            <p>No hay membresías disponibles</p>
          )}
        </div>
      )}
    </TopBarScreen>
  );

  function MembershipCard({
    subscription,
    active
  }: {
    subscription: Subscription;
    active?: boolean;
  }) {
    return (
      <div className="m-8 flex mb-14">
        <img
          className={
            'flex flex-col w-[87%]  h-[250px] rounded absolute z-10' +
            (!active ? 'opacity-100' : 'opacity-60')
          }
          src={active ? '/imgs/bg-green.png' : '/imgs/bg-blue.png'}></img>
        <div
          className="flex flex-col w-full h-[250px] z-20"
          onClick={() => {
            navigate(`/miPerfil/membresias/membresia`, {
              state: {
                subscription,
                currentSubscription,
                futureSubscription
              }
            });
          }}>
          <div className="w-full h-[80px]">
            <p className=" w-full h-full text-center my-[5%] text-3xl font-bold">{`$${subscription.monthly_fee} Al Mes`}</p>
          </div>
          <div className={'w-full h-[170px]   flex  '}>
            <div className=" h-full w-[66%] pl-2">
              <div>
                <p className="font-bold mt-2">Membresía:</p>
                <p>{subscription.name}</p>
              </div>
              <div>
                <p className="font-bold mt-4">Descripción</p>
                <p>
                  {subscription.description.length > 100
                    ? subscription.description.slice(0, 100) + '...'
                    : subscription.description}
                </p>
              </div>
            </div>
            <div className="h-full w-[34%] text-right pr-2">
              <div>
                <p className="font-bold mt-2">Duración:</p>
                <p className="text-right">{'30 días'}</p>
              </div>
              <div>
                <p className="font-bold mt-4 text-right">Créditos al mes</p>
                <p className="text-right">{subscription.credit_ammount}</p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
