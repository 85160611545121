import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getModelByName } from '../../../apis/models';

import { buttons, texts } from '../../../constants/styles';
import { Order } from '../../../models/interfaces';
import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import { orderStatusString } from '../../../utils/orderStatusConverter';
import { RandomID } from '../../../utils/randomIDGenerator';
import CartItem from '../../CartItem';
import OrderStepper from '../../OrderStepper';
import { SupportDialog } from './SupportDialog';

const styles = {
  dataItem: 'mb-3',
  label: 'font-semibold',
  value: ''
};

export default function OrderDetails() {
  const [order] = useState<Order>(useLocation().state?.order);

  const [products, setProducts] = useState<any>([]);

  const isInWorkerSupport =
    order.is_in_support &&
    order.support_data.created_by == 'worker' &&
    order.support_data.contact_value.length <= 1;
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState<boolean>(isInWorkerSupport);

  const daysLeftToReturn = order.end_of_cycle
    ? Math.ceil(
        (new Date(order.end_of_cycle).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
      ) - 1
    : 100;

  const daysPassedWhenCreated = order.date_created
    ? Math.ceil(
        (new Date().getTime() - new Date(order.date_created).getTime()) / (1000 * 3600 * 24)
      )
    : 100;

  // Get models data
  async function getModelsData() {
    const prods = order?.products;
    const promises = prods.map((product: any) => getModelByName(product?.model));
    const responses = await Promise.all(promises);

    const productsData: any[] = [];
    prods.forEach((item: any) => {
      const modelData: any = {
        size: item?.size,
        model_id: item?.model,
        data: responses.find((product) => product?.model_id === item?.model)
      };
      productsData.push(modelData);
    });

    setProducts(productsData);
  }

  function showToast(success: boolean) {
    if (success) {
      toast.success('Los Datos de contacto se han actualizado correctamente', {
        position: 'top-center'
      });
    } else {
      toast.error(
        'Hubo un error al actualizar los datos de soporte, intentale de nuevo mas tarde.',
        { position: 'top-center' }
      );
    }
  }

  useEffect(() => {
    getModelsData();
    console.log(daysLeftToReturn);
  }, []);

  return (
    <TopBarScreen backRoute="../../miPerfil/pedidos">
      <ToastContainer></ToastContainer>
      <SupportDialog
        setToast={showToast}
        order={order}
        order_id={order.order_id}
        setOpen={setIsSupportDialogOpen}
        open={isSupportDialogOpen}></SupportDialog>
      <div className="rounded-lg shadow-xl max-w-[600px] w-full relative mx-auto mt-10 py-2 mb-[50px]">
        <h1 className={texts.title}>Detalles de Pedido</h1>

        {/* Stepper */}
        <div className="mt-12">
          <OrderStepper curStep={order?.status} />
        </div>

        {order.is_in_support && (
          <p className="bg-orange-600 text-white text-xl text-center p-4">
            El pedido Está en Soporte
          </p>
        )}

        {/* Details */}
        <div className="grid grid-cols-2 mt-11 px-10 gap-3">
          <div className={styles.dataItem}>
            <p className={styles.label}>Estado:</p>
            <p className={styles.value}>{orderStatusString(order?.status)}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.label}>Fecha de Orden:</p>
            <p className={styles.value}>
              {new Date(order?.date_created).toLocaleDateString('es-ES')}
            </p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.label}>Total de Créditos:</p>
            <p className={styles.value}>$ {order?.total_credits}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.label}>Dirección de Entrega:</p>
            <p
              className={
                styles.value
              }>{`${order.address.street}, #${order.address.exterior_number}, ${order.address.postal_code}`}</p>
          </div>
          {order.delivery_url && !order.return_url && (
            <div>
              <p className={styles.label}>¿Dónde está mi pedido?</p>
              <p
                onClick={() => {
                  if (order.delivery_url?.includes('http')) {
                    window.open(order.delivery_url, '_blank');
                  } else {
                    window.open('https://' + order.delivery_url, '_blank');
                  }
                  //
                }}
                className={styles.value + ' text-blue-600 underline'}>
                Ver estado de reparto
              </p>
            </div>
          )}
          {order.end_of_cycle && (
            <div>
              <p className={styles.label}>Fecha estimada de devolución:</p>
              <p className={styles.value}>
                {new Date(order?.end_of_cycle).toLocaleDateString('es-ES')}
              </p>
            </div>
          )}
          {order.return_url && (
            <div>
              <p className={styles.label}>¿Cuándo recogen mis prendas?</p>
              <p
                onClick={() => {
                  if (order.return_url?.includes('http')) {
                    window.open(order.return_url, '_blank');
                  } else {
                    window.open('https://' + order.return_url, '_blank');
                  }
                }}
                className={styles.value + ' text-blue-600 underline'}>
                Ver estado de recogida
              </p>
            </div>
          )}
        </div>

        {/* Product Items */}
        <div className="px-7 mt-10 mb-9">
          <h3 className="text-primary text-[20px] font-semibold mb-3">Productos:</h3>
          {products.map((product: any) => (
            <div key={RandomID()}>
              <CartItem available item={product} onlyView={true} removeCartItem={() => {}} />
            </div>
          ))}
        </div>
        <div className="px-7">
          {order.return_url ? (
            <div className="bg-orange-200 p-4 my-4 rounded">
              <p className="text-center text-gray-600">
                Estamos por recoger tu pedido,consulta el estado aquí:
              </p>
              <p
                onClick={() => {
                  if (order.return_url?.includes('http')) {
                    window.open(order.return_url, '_blank');
                  } else {
                    window.open('https://' + order.return_url, '_blank');
                  }
                }}
                className={styles.value + ' text-blue-600 underline text-center'}>
                Ver estado de recogida
              </p>
            </div>
          ) : (
            <>
              {daysLeftToReturn <= 5 && daysLeftToReturn >= 0 && (
                <div className="bg-orange-100 p-4 my-4 rounded">
                  <p className="text-center text-gray-600">
                    {'Faltan ' +
                      daysLeftToReturn +
                      ' días para que tus prendas sean recogidas. Si deseas cambiarlo o tienes alguna duda, contacta a soporte'}
                  </p>
                  <p className="text-center text-gray-600 mt-4">
                    Recuerda que debes de tener tus prendas listas y empaquetadas.
                  </p>
                </div>
              )}

              <div className="bg-green-100 p-4 my-4 rounded">
                <p className="text-center text-gray-600">
                  ¿Quieres quedarte una prenda?, contacta a soporte para que te ayuden a realizarlo.
                </p>
              </div>
            </>
          )}
          {daysPassedWhenCreated <= 5 && (
            <div className="bg-orange-200 p-4 my-4 rounded">
              <p className="text-center text-gray-600">
                ¿Llegó mal una prenda?, contacta a soporte. Tienes 24 horas para contactarlos
                después de que haya llegado tu pedido.
              </p>
            </div>
          )}
          <button
            className={buttons.primaryButton}
            onClick={() => {
              setIsSupportDialogOpen(true);
            }}>
            <p className={texts.primaryButonText}>
              {order.is_in_support ? 'Editar datos de Soporte' : 'Contactar a Soporte'}
            </p>
          </button>
        </div>
      </div>
    </TopBarScreen>
  );
}
