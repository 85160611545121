import { createContext, useEffect, useState } from 'react';
import { getUser } from '../apis/users';
import LoadingScreen from '../components/Views/LoadingScreen';
import { auth } from '../firebaseConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        getUser(user.uid).then((res) => {
          setCurrentUserData(res.data);
          setCurrentUser(user);
          setPending(false);
        });
      } else {
        setCurrentUser(null);
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, currentUserData, setCurrentUser, setCurrentUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
