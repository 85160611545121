import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function InfoScreen({
  children,
  title,
  backPath
}: {
  children: JSX.Element;
  title: string;
  backPath?: string;
}) {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col items-center">
      {/* Header */}
      <div className="h-[100px] w-full">
        <img src="/imgs/landing/main.png" className="w-full h-full object-cover object-bottom" />
      </div>

      {/* Content */}
      <div className="w-full max-w-[1100px] md:p-10 p-5">
        {/* Title */}
        <div className="flex border-b border-primary pb-1 mb-10 items-end">
          <IconButton onClick={() => navigate(backPath || '/')}>
            <ArrowBackIcon />
          </IconButton>
          <h1 className="text-[28px] ml-4 font-[500] text-gray-800">{title}</h1>
        </div>
        {children}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}
