import { useContext, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { updateUser } from '../../apis/users';
import { buttons, inputs, texts } from '../../constants/styles';
import { AuthContext } from '../../providers/Auth';
import TopBarScreen from '../layouts/TopBarScreen';

export default function ProfileSettingsScreen() {
  const { currentUserData, currentUser } = useContext(AuthContext);
  const [name, setName] = useState<string>(currentUserData.name || '');
  const [email, setEmail] = useState<string>(currentUserData.email || '');

  async function updateProfile() {
    await updateUser({ id: currentUser.uid, user: currentUserData, name: name, email: email }).then(
      (res) => {
        if (res) {
          toast.success('Tu perfil ha sido actualizado correctamente.', { position: 'top-center' });
        } else {
          toast.error('Hubo un error al actualizar tu perfil.', { position: 'top-center' });
        }
      }
    );
  }

  return (
    <TopBarScreen backRoute="../miPerfil">
      <div className="my-[5%]">
        <div className=" max-w-[500px] relative mx-auto flex">
          <div className="bg-primary relative mx-auto h-[100px] w-[100px] rounded-full "></div>
        </div>
        <div className="rounded-lg shadow-xl  max-w-[500px] relative mx-auto mt-10 px-4 py-2">
          <div>
            <p className="font-semibold mb-2 mt-8">Nombre</p>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className={inputs.primaryInput}
              placeholder={currentUserData.name || ''}></input>
          </div>

          <div className=" mb-5">
            <p className="font-semibold mb-2 mt-4">Email</p>
            <input
              disabled
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={inputs.primaryInput}
              placeholder={currentUserData.email || ''}></input>
          </div>
          <div className=" mb-5">
            <p className="font-semibold mb-2 mt-4">Número celular</p>
            <input
              value={currentUserData.phone_number || ''}
              disabled
              className={inputs.primaryInput}
              placeholder={currentUserData.phone_number || ''}></input>
          </div>
          <button
            onClick={() => {
              updateProfile();
            }}
            className={buttons.primaryButton}>
            <p className={texts.primaryButonText}>Guardar cambios</p>
          </button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </TopBarScreen>
  );
}
