import axios from 'axios';
import { Address } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function addAddress(id: string, address: Address) {
  try {
    await axios.post(`${url}/addresses/new/${id}`, address);
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in addAddress');
    return false;
  }
}
