import { useContext, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { deleteUserImage, updateImage } from '../../apis/users';

import Authentication from '../../components/Authentication';
import { divs } from '../../constants/styles';

import { AuthContext } from '../../providers/Auth';
import ImagePathConverter from '../../utils/imagePathConverter';
import DashboardAndTopbarScreen from '../layouts/DashboardAndTopbarScreen';
import { uploadSingleImage } from '../../utils/images';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

export default function ProfileScreen() {
  const { currentUser, currentUserData } = useContext(AuthContext);

  const [imageURL, setImageURL] = useState<string>('');
  const [loadingImage, setLoadingImage] = useState<boolean>(true);
  const inputRef = useRef<any>(null);
  const [imgMenuAnchorEl, setImgMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openImgMenu = Boolean(imgMenuAnchorEl);

  // Render user image
  function renderUserImg(newImage: File) {
    if (newImage) {
      setImageURL(URL.createObjectURL(newImage));
    } else {
      setImageURL('');
    }
  }
  useEffect(() => {
    if (currentUserData !== undefined && currentUserData !== null) {
      if (
        currentUserData.user_img !== null &&
        currentUserData.user_img !== undefined &&
        currentUserData.user_img !== ''
      ) {
        ImagePathConverter(currentUserData?.user_img).then((imgUrl) => {
          setImageURL(imgUrl);
        });
      }
      setLoadingImage(false);
    }
  }, [currentUserData, currentUser]);

  async function updateImg(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files === null) return;

    // Upload image from frontend
    const resUpload = await uploadSingleImage(e.target.files[0]);
    if (!resUpload.status) {
      toast.error(resUpload.msg);
      return;
    }
    const newImgData = {
      filePath: resUpload?.fileRef?.fullPath,
      fileName: resUpload?.fileRef?.name
    };

    // Update user doc
    const res = await updateImage(currentUser.uid, newImgData);

    // If error
    if (!res) {
      toast.error('Hubo un error al actualizar tu imágen', { position: 'top-center' });
      return;
    }

    // Success
    toast.success('Imagen actualizada correctamente', { position: 'top-center' });
    renderUserImg(e.target.files[0]);
  }

  function deleteImg() {
    deleteUserImage(currentUser.uid).then((res) => {
      setImageURL('');
      if (res) {
        toast.success('Imagen eliminada correctamente', { position: 'top-center' });
      } else {
        toast.error('Hubo un error al eliminar tu imágen', { position: 'top-center' });
      }
    });
  }

  return (
    <DashboardAndTopbarScreen screen="miPerfil">
      <div className="my-[5%]">
        <div className="max-w-[500px] relative mx-auto flex items-center sm:mt-0 mt-5">
          <div className="relative">
            {!loadingImage && (
              <img
                src={imageURL}
                className={'bg-primary h-[100px] w-[100px] object-cover rounded-full ml-5'}></img>
            )}
            <div className="absolute bottom-0 right-0 bg-[#d0eeda] rounded-full">
              <IconButton
                size="small"
                color="primary"
                onClick={(e) => setImgMenuAnchorEl(e.currentTarget)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
            {/* Img menu */}
            <Menu
              anchorEl={imgMenuAnchorEl}
              open={openImgMenu}
              onClose={() => setImgMenuAnchorEl(null)}>
              <MenuItem
                onClick={() => {
                  inputRef.current.click();
                }}>
                {imageURL.length > 0 ? 'Cambiar Foto' : 'Agregar Foto'}
              </MenuItem>
              {imageURL.length > 0 && (
                <MenuItem
                  sx={{ color: 'crimson' }}
                  onClick={() => {
                    deleteImg();
                  }}>
                  Eliminar Foto
                </MenuItem>
              )}
            </Menu>

            <input
              type="file"
              ref={inputRef}
              style={{ display: 'none' }} // It doesn't display
              accept="image/*"
              onChange={(e) => {
                updateImg(e);
              }}></input>
          </div>
          <div className="ml-6">
            <p className="text-2xl text-gray-400">Hola,</p>
            <p className="text-4xl text-primary">{currentUserData.name}</p>
          </div>
        </div>
        <div className={divs.content}>
          <div className="p-7 py-9">
            <div className="flex  place-content-between">
              <p className="text-xl ">Créditos disponibles</p>
              <p className="text-xl font-semibold text-primary">
                {currentUserData.credits ? currentUserData.credits : 0}
              </p>
            </div>
            <div className="flex place-content-between mt-4">
              <div className="flex mr-4 items-center">
                <p className="mr-3">Pedidos Disponibles:</p>
                <p className="font-semibold text-primary">
                  {currentUserData.available_orders ? currentUserData.available_orders : 0}
                </p>
              </div>
              <div className="flex ml-4 items-center">
                <p className=" mr-3">Devoluciones Disponibles:</p>
                <p className="font-semibold text-primary">
                  {currentUserData.available_returns ? currentUserData.available_returns : 0}
                </p>
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="flex justify-center">
            <div className="h-[1px] w-[90%] bg-gray-200 mb-2" />
          </div>
          <SectionItem
            title="Ajustes de perfil"
            icon={<PersonOutlineIcon fontSize="small" />}
            href="ajustes"></SectionItem>
          <SectionItem
            title="Direcciones"
            icon={<FmdGoodOutlinedIcon fontSize="small" />}
            href="direcciones"></SectionItem>
          <SectionItem
            title="Métodos de pago"
            icon={<PaymentOutlinedIcon fontSize="small" />}
            href="metodosDePago"></SectionItem>
          <SectionItem
            title="Pedidos"
            icon={<ShoppingBasketOutlinedIcon fontSize="small" />}
            href="pedidos"></SectionItem>
          <SectionItem
            title="Membresías"
            icon={<BeenhereOutlinedIcon fontSize="small" />}
            href="membresias"></SectionItem>
          <SectionItem
            title="Favoritos"
            icon={<FavoriteBorderOutlinedIcon fontSize="small" />}
            href="favoritos"></SectionItem>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    </DashboardAndTopbarScreen>
  );
}

interface SectionItemProps {
  title: string;
  icon: any;
  href: string;
}
function SectionItem({ title, icon, href }: SectionItemProps) {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/miPerfil/${href}`);
  return (
    <div
      className="my-5 place-content-between flex px-3 items-center cursor-pointer"
      onClick={handleClick}>
      <div className="flex flex-row items-center">
        <div className="bg-primary h-[40px] w-[40px] rounded-full ml-4 flex justify-center items-center text-white">
          {icon}
        </div>
        <p className="cursor-pointer ml-5 mb-1 text-[18px]">{title}</p>
      </div>
      <IconButton>
        <ArrowForwardIosOutlinedIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
