import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { updateUser } from '../../../apis/users';
import { buttons, divs, inputs, texts } from '../../../constants/styles';
import { Address } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import TopBarScreen from '../../../screens/layouts/TopBarScreen';

export default function AddressView() {
  const location = useLocation();
  const address = location.state as Address;

  const [street, setStreet] = useState<string>(address.street || '');
  const [exteriorNumber, setExteriorNumber] = useState<string>(address.exterior_number || '');
  const [interiorNumber, setInteriorNumber] = useState<string>(address.interior_number || '');
  const [postalCode, setPostalCode] = useState<string>(address.postal_code || '');
  const [tag, setTag] = useState<string>(address.tag || '');
  const [description, setDescription] = useState<string>(address.description || '');

  const { currentUser, currentUserData } = useContext(AuthContext);
  const [addresses] = useState<Address[]>(currentUserData?.adresses);

  function updateAddress() {
    const updatedAddress = addresses.filter((a: Address) => a !== address);
    updatedAddress.push({
      street: street,
      exterior_number: exteriorNumber,
      interior_number: interiorNumber,
      postal_code: postalCode,
      tag: tag,
      description: description
    });

    if (updateAddress !== currentUserData?.adresses) {
      updateUser({ id: currentUser.uid, user: currentUserData, adresses: updatedAddress }).then(
        (e) => {
          if (e) {
            toast.success('Dirección actualizada', { position: 'top-center' });
            setTimeout(() => {
              window.location.href = '/miPerfil/direcciones';
            }, 2000);
          } else {
            toast.error('Error al actualizar dirección', { position: 'top-center' });
          }
        }
      );
    }
  }

  return (
    <TopBarScreen backRoute="../miPerfil/direcciones">
      <div className={divs.content}>
        <h1 className={texts.title}>Agregar nueva dirección</h1>
        <InputWithHint onChange={setStreet} value={street} hint="Calle"></InputWithHint>
        <InputWithHint
          onChange={setExteriorNumber}
          value={exteriorNumber}
          hint="Número exterior"></InputWithHint>
        <InputWithHint
          onChange={setInteriorNumber}
          value={interiorNumber}
          hint="Número interior"></InputWithHint>
        <InputWithHint
          onChange={setPostalCode}
          value={postalCode}
          hint="Código postal"></InputWithHint>
        <InputWithHint onChange={setTag} value={tag} hint="Tag"></InputWithHint>
        <div className="m-4">
          <p className="text-lg pl-1">{'Descrición'}</p>
          <textarea
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            className={inputs.primaryInput}></textarea>
        </div>

        <div className="mx-4">
          <button
            onClick={() => {
              updateAddress();
            }}
            disabled={
              street.length === 0 ||
              exteriorNumber.length === 0 ||
              postalCode.length === 0 ||
              tag.length === 0
            }
            className={
              street.length === 0 ||
              exteriorNumber.length === 0 ||
              postalCode.length === 0 ||
              tag.length === 0
                ? buttons.disabledButton
                : buttons.primaryButton
            }>
            <p className={texts.primaryButonText}>Guardar</p>
          </button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </TopBarScreen>
  );
}

function InputWithHint({
  hint,
  value,
  onChange
}: {
  value: string;
  hint: string;
  onChange: Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="m-4">
      <p className="text-lg pl-1">{hint}</p>
      <input
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        className={inputs.primaryInput}></input>
    </div>
  );
}
