import { buttons, texts } from '../../constants/styles';
import Footer from '../layouts/Footer';
import AwaitingList from './Components/Awaiting_list';

export default function LandingScreen() {
  return (
    <div className="w-full">
      {
        // This div is to simulate what you are going to put as landing,
      }
      {/* Main Image */}
      <div className="w-full h-[300px]">
        <img src="/imgs/landing/main.png" className="w-full h-full object-cover object-bottom" />
      </div>

      {/* Logo */}
      <div className="flex justify-center mt-10 h-[200px]">
        <img src="/imgs/logo.png" alt="" className="w-[300px] object-cover" />
      </div>

      {/* Explanation */}
      <div className="w-full flex justify-center mb-[15vh]">
        <div className="text-center md:max-w-[70%] max-w-[85%]">
          <h1 className="mb-8 text-[25px]">/share-fair/</h1>
          <p className="text-gray-600 text-[18px] mb-5">
            Somos el inicio de una moda transparente, sustentable, justa.
          </p>
          <p className="text-gray-600 text-[18px] mb-5">
            Queremos que la novedad deje de significar un acto negativo en el planeta, que un cambio
            de ropa represente un cambio de historia.
          </p>
          <p className="text-gray-600 text-[18px] mb-9">
            Creemos en el arte de compartir, y pensamos que un nuevo ciclo solo puede significar la
            llegada de algo mejor.
          </p>

          <p className="text-gray-600 text-[18px]">Somos Xerfer.</p>
        </div>
      </div>

      <div className="w-full flex justify-center">
        <div className=" w-[150px]  md:w-[300px] justify-center ">
          <button
            className={buttons.primaryButton + ' py-[20px]'}
            onClick={() => {
              window.location.href = '/home';
            }}>
            <p className={texts.primaryButonText + ' text-xl'}>COMIENZA A EXPLORAR</p>
          </button>
        </div>
      </div>

      {/* Circle */}
      <div
        className="w-full flex md:flex-row flex-col-reverse md:h-[600px] md:[100vh] my-[15vh]"
        id="como_funciona">
        <div className="md:w-[50%] h-full flex justify-center items-center bg-[#fdfffd]">
          <img src="/imgs/landing/circle.png" alt="" className="w-[95%] h-[95%] object-contain" />
        </div>
        <div className="md:w-[50%] md:h-full h-[800px] bg-[#E9F8FF] flex items-center justify-center md:mb-0 mb-10">
          <div className="md:max-w-[80%] max-w-[85%] text-center">
            <p className="text-primary text-[26px] font-[500] mb-6">¿COMO FUNCIONA?</p>
            <p className="mb-4 text-[16px] text-gray-800">
              *Tu membresía incluye un envío y devolución al mes, así como el servicio de lavandería
              y tintorería de las prendas.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">*Sin permanencia mínima.</p>
            <p className="mb-4 text-[16px] text-gray-800">
              *Todas las membresías incluyen un seguro contra daños pequeños y reparables, para
              aprovechar al máximo la vida útil de cada pieza.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">
              *En caso de daños mayores o irreparables, se deberá cubrir el costo de la prenda.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">
              *Las membresías varían en la cantidad de créditos con los que se arma el kit de
              prendas.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">
              *Los créditos son acumulativos y no tienen vencimiento: en caso de no agotarlos todos
              en un pedido, podrán ser utilizados en el siguiente.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">
              *En caso de necesitar más créditos, es posible adquirirlos sin necesidad de cambiar el
              nivel de membresía.
            </p>
            <p className="mb-4 text-[16px] text-gray-800">
              *Se puede cambiar de membresía siempre que quieras
            </p>
          </div>
        </div>
      </div>

      {/* Why */}
      <div className="w-full bg-[#98CBA2] flex flex-col items-center h-[500px] justify-center mb-[15vh] overflow-hidden">
        <h1 className="text-white text-[27px] font-[500] mb-7">¿POR QUÉ ALQUILAR?</h1>
        <div className="bg-[#ffffff] md:w-[90%] w-full h-[350px] md:overflow-auto overflow-x-scroll light_scroll">
          <div className="flex justify-evenly items-center w-[1000px] md:w-full h-full">
            <img src="/imgs/landing/item1.png" alt="" className="w-[17%] h-[80%] object-contain" />
            <img src="/imgs/landing/item2.png" alt="" className="w-[17%] h-[80%] object-contain" />
            <img src="/imgs/landing/item3.png" alt="" className="w-[17%] h-[80%] object-contain" />
            <img src="/imgs/landing/item4.png" alt="" className="w-[17%] h-[80%] object-contain" />
            <img src="/imgs/landing/item5.png" alt="" className="w-[17%] h-[80%] object-contain" />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center">
        <div className=" w-[150px]  md:w-[300px] justify-center ">
          <button
            className={buttons.primaryButton + ' py-[20px]'}
            onClick={() => {
              window.location.href = '/home';
            }}>
            <p className={texts.primaryButonText + ' text-xl'}>COMIENZA A EXPLORAR</p>
          </button>
        </div>
      </div>

      <AwaitingList></AwaitingList>

      <Footer />
    </div>
  );
}
