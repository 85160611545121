import InfoScreen from '../layouts/InfoScreen';

export default function UsScreen() {
  return (
    <InfoScreen title="Nosotros">
      <div className="flex justify-center items-center h-[300px]">
        <p>Nosotros</p>
      </div>
    </InfoScreen>
  );
}
