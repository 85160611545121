import { MenuItem, Select } from '@mui/material';
import { buttons, texts } from '../../constants/styles';
import InfoScreen from '../layouts/InfoScreen';
import { useState } from 'react';
import { ContactUsProps, contactUs } from '../../apis/users';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default function ContactScreen() {
  const [contactType, setContactType] = useState<string>('Mensaje de texto');
  const [contactValue, setContactValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  async function SendSupportData() {
    const data = {
      contact_method: contactType,
      contact_value: contactValue,
      message: message,
      type: 'question'
    } as ContactUsProps;
    contactUs(data).then((e) => {
      if (e) {
        toast.success(
          'Enviaste tus datos correctamente, pronto nos pondremos en contacto contigo.',
          { position: 'top-center' }
        );
        setContactType('Mensaje de texto');
        setContactValue('');
        setMessage('');
      } else {
        toast.error('Ocurrió un error al enviar tus datos, intenta de nuevo más tarde.', {
          position: 'top-center'
        });
      }
    });
  }

  return (
    <InfoScreen title="Contacto">
      <div className=" justify-center items-center ">
        <ToastContainer></ToastContainer>
        <div className="h-[200px] ">
          <h1 className={texts.title}>Contactanos</h1>
          <p>
            Sección De contacto, en esta parte van los datos donde el cliente puede contactarnos
          </p>
        </div>
        <div className="border-2 rounded-lg flex flex-col items-center">
          <h1 className={texts.title}>Nosotros te contactamos</h1>
          <p className={texts.subtitle + ' pb-6 px-4'}>
            Introduce tus datos y nosotros nos pondermos en contacto contigo lo antes posible.
          </p>

          <div className=" py-6 text-center  ">
            <p className="pb-4">¿Cómo quieres que te contactemos?</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              value={contactType}
              label="Age"
              onChange={(e) => {
                setContactType(e.target.value);
              }}>
              <MenuItem value={'Mensaje de texto'}>Mensaje de texto</MenuItem>
              <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem>
              <MenuItem value={'Llamada telefónica'}>Llamada telefónica</MenuItem>
              <MenuItem value={'Email'}>Correo Electrónico</MenuItem>
            </Select>
          </div>

          <div className=" py-6  text-center  ">
            <p className="pb-4">Ingresa el método de contacto</p>
            <input
              value={contactValue}
              onChange={(e) => {
                setContactValue(e.target.value);
              }}
              className="bg-grey px-4 py-2 shadow rounded w-[300px]"></input>
          </div>

          <div className=" py-6 text-center">
            <p className="pb-4">¿En qué Podemos ayudarte?</p>
            <textarea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="bg-grey px-4 py-2 shadow rounded w-[300px] max-h-[300px] min-h-[150px]"></textarea>
          </div>

          <div className="mb-6">
            <div className="flex items-center">
              <Checkbox
                size="small"
                value={termsAccepted}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTermsAccepted(event.target.checked)
                }
              />
              <p className="mb-[1px] text-[14px]">
                Aceptar{' '}
                <Link to="/terminos-condiciones" className="text-primary underline">
                  Términos y Condiciones
                </Link>
              </p>
            </div>
            <div className="flex items-center">
              <Checkbox
                size="small"
                value={privacyAccepted}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPrivacyAccepted(event.target.checked)
                }
              />
              <p className="mb-[1px] text-[14px]">
                Aceptar{' '}
                <Link to="/politica-privacidad" className="text-primary underline">
                  Política de Privacidad
                </Link>
              </p>
            </div>
          </div>

          <div className="text-center w-full">
            {message.length < 1 || contactValue.length < 1 || !privacyAccepted || !termsAccepted ? (
              <button
                className={buttons.disabledButton + ' max-w-[300px]'}
                disabled={message.length < 1 || contactValue.length < 1}>
                <p className={texts.primaryButonText}>Enviar</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  SendSupportData();
                }}
                className={buttons.primaryButton + ' max-w-[300px]'}
                disabled={message.length < 1 || contactValue.length < 1}>
                <p className={texts.primaryButonText}>Enviar</p>
              </button>
            )}
          </div>
        </div>
      </div>
    </InfoScreen>
  );
}
