import InfoScreen from '../layouts/InfoScreen';

export default function FAQScreen() {
  return (
    <InfoScreen title="Preguntas Frecuentes">
      <div className="flex justify-center items-center h-[300px]">
        <p>Preguntas Frecuentes</p>
      </div>
    </InfoScreen>
  );
}
