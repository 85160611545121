import React from 'react';
import { Subscription } from '../models/interfaces';

export default function MembershipData({ subscription }: { subscription: Subscription }) {
  return (
    <div className={'m-8 flex '}>
      <img
        className={
          'flex flex-col w-[87%] h-[250px] rounded absolute z-10  ' +
          (!subscription.userSubscription ? 'opacity-100' : 'opacity-60')
        }
        src={subscription.userSubscription ? '/imgs/bg-green.png' : '/imgs/bg-blue.png'}></img>
      <div className="flex flex-col w-full h-[250px] z-20">
        <div className="w-full h-[80px]">
          <p className=" w-full h-full text-center my-[5%] text-3xl font-bold">{`$${subscription.monthly_fee} Al Mes`}</p>
        </div>
        <div className={'w-full h-[170px]   flex  '}>
          <div className=" h-full w-[66%] pl-2">
            <div>
              <p className="font-bold mt-2">Membresía:</p>
              <p>{subscription.name}</p>
            </div>
            <div>
              <p className="font-bold mt-4">Descripción</p>
              <p>
                {subscription.description.length > 100
                  ? subscription.description.slice(0, 100) + '...'
                  : subscription.description}
              </p>
            </div>
          </div>
          <div className="h-full w-[34%] text-right pr-2">
            <div>
              <p className="font-bold mt-2">Duración:</p>
              <p className="text-right">{'30 días'}</p>
            </div>
            <div>
              <p className="font-bold mt-4 text-right">Créditos al mes</p>
              <p className="text-right">{subscription.credit_ammount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
