import { Button } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  buySubscription,
  cancelSubscription,
  getUserActiveSubscriptions
} from '../../apis/subscriptions';
import { divs, texts } from '../../constants/styles';
import { Subscription } from '../../models/interfaces';
import TopBarScreen from '../layouts/TopBarScreen';
import { AuthContext } from '../../providers/Auth';
import ActiveMembershipData from '../../components/ActiveMembershipData';
import { AlertDialog } from '../../components/AlertDialog';
import MembershipData from '../../components/MembershipData';
import MembershipButtons from '../../components/MembershipButtons';

export const buttonsActions = {
  buySubscription: 1,
  buyPreorderedSubscription: 2,
  changePreorderedSubscription: 3,
  cancelSubscription: 4
};

export default function MembershipScreen() {
  const { currentUser, currentUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const subscriptionLocation = location.state?.subscription as Subscription;
  const currentSubscription = location.state?.currentSubscription as Subscription;
  const futureSubscription = location.state?.futureSubscription as Subscription;
  const { selectedPaymenthMethod } = location.state;
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [paymentMethod] = useState(selectedPaymenthMethod || null);
  const [title, setTitle] = useState('Contratar Membresía');
  const [isActive, setIsActive] = useState(false);
  // const [isPreordered, setIsPreordered] = useState(false);

  // Dialog Data
  const [dialogMode, setDialogMode] = useState<number | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');

  useEffect(() => {
    if (subscriptionLocation) {
      setSubscription(subscriptionLocation);
      const subscriptionStatus = subscriptionLocation.userSubscription?.status;
      if (subscriptionStatus === 'active') {
        setIsActive(true);
        setTitle('Membresía Actual');
      }
    }
  }, []);

  async function buySubscriptionHandler() {
    // Validate ids
    if (!currentUser.uid || !subscription?.id) {
      toast.error('Hubo un error al cancelar la membresía', { position: 'top-center' });
      return;
    }
    if (!paymentMethod || !paymentMethod?.id) {
      toast.error('Método de pago inválido', { position: 'top-center' });
      return;
    }

    getUserActiveSubscriptions(currentUser.uid).then((res) => {
      if (res != false) {
        res?.forEach((sub) => {
          cancelSubscription(currentUser.uid, sub.id);
        });
      }
    });
    const res = await buySubscription(currentUser.uid, paymentMethod?.id, subscription?.id);
    if (!res) {
      toast.error('Hubo un error al contratar la membresía', { position: 'top-center' });
      return;
    }

    // Success
    toast.success('Membresía contratada', { position: 'top-center' });
    navigate('/miPerfil/membresias');
  }

  async function cancelSubscriptionHandler() {
    // Validate ids
    if (
      !subscription?.userSubscription?.user_id ||
      !subscription?.userSubscription?.subscription_id
    ) {
      toast.error('Hubo un error al cancelar la membresía', { position: 'top-center' });
      return;
    }

    const res = await cancelSubscription(currentUser?.uid, subscription?.userSubscription?.id);
    if (!res) {
      toast.error('Hubo un error al cancelar la membresía', { position: 'top-center' });
      return;
    }

    // Success
    toast.success('Membresía cancelada', { position: 'top-center' });
    navigate('/miPerfil/membresias');
  }

  async function openDialogHandler(action: number) {
    switch (action) {
      case buttonsActions.buySubscription: {
        setDialogTitle('Contratar Membresía');
        setDialogDescription(
          `La membresía tiene una duración de 30 días, se renovará automáticamente al finalizar cada periodo.
          Al contratar la membresía se cancelará la membresía actual si la tiene, y se renovará automáticamente al finalizar cada periodo.
           `
        );
        setDialogMode(action);
        setShowDialog(true);
        break;
      }
      case buttonsActions.cancelSubscription: {
        setDialogTitle('¿Está seguro que desea cancelar esta membresía?');
        setDialogDescription(
          'Seguirás teniendo los créditos, pedidos y devoluciones actuales, solo que no se renovará automáticamente al finalizar el periodo.'
        );

        setDialogMode(action);
        setShowDialog(true);
        break;
      }
    }
  }

  async function agreeDialogHandler() {
    switch (dialogMode) {
      case buttonsActions.buySubscription: {
        buySubscriptionHandler();
        break;
      }

      case buttonsActions.cancelSubscription: {
        cancelSubscriptionHandler();
        break;
      }
      default:
        return;
    }
  }

  if (subscription === null) {
    return <div>404 - Ruta vacía</div>;
  }

  return (
    <TopBarScreen backRoute="../miPerfil/membresias">
      <div className={divs.content}>
        <h1 className={texts.title}>{title}</h1>

        {/* Membership Data */}
        <MembershipData subscription={subscription} />

        {/* Active Membership Data */}
        {isActive && <ActiveMembershipData subscription={subscription} />}

        {/* Select Payment Method */}
        {!isActive && currentUserData && (
          <div className="mx-9 pb-2 border-b-2">
            <p className="font-semibold mb-2">Método de Pago:</p>
            {paymentMethod ? (
              <p className="text-blue-700">{`${paymentMethod?.card?.brand?.toUpperCase()} ************${
                paymentMethod?.card?.last4
              }`}</p>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate(`/miPerfil/metodosDePago`, {
                    state: {
                      selectMode: true,
                      nextPath: '/miPerfil/membresias/membresia',
                      subscription: subscriptionLocation,
                      currentSubscription,
                      futureSubscription
                    }
                  });
                }}>
                Seleccionar Método
              </Button>
            )}
          </div>
        )}

        {/* Buttons */}
        <div className="flex flex-col w-full items-center mb-7 mt-6">
          {MembershipButtons({
            isActive,
            currentSubscription,
            openDialogHandler
          })}
        </div>
      </div>

      <AlertDialog
        open={showDialog}
        setOpen={setShowDialog}
        title={dialogTitle}
        description={dialogDescription}
        agreeText="Continuar"
        agreeAction={agreeDialogHandler}
        disagreeText="Cancelar"
      />

      <ToastContainer />
    </TopBarScreen>
  );
}
