import InfoScreen from '../layouts/InfoScreen';

export default function CookiesPolicyScreen() {
  return (
    <InfoScreen title="Políticas de Cookies (UE)">
      <div className="flex justify-center items-center h-[300px]">
        <p>Políticas de Cookies (UE)</p>
      </div>
    </InfoScreen>
  );
}
