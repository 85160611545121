import React, { useState } from 'react';
import { Subscription } from '../models/interfaces';
import { getMembershipDate, getMembershipStatus } from '../utils/membershipConverters';
import { faCheck, faClock, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ActiveMembershipData({ subscription }: { subscription: Subscription }) {
  const [isActive] = useState(subscription?.userSubscription?.status === 'active');
  const [isPreordered] = useState(subscription?.userSubscription?.status === 'preordered');

  return (
    <div className="m-8">
      {/* Status */}
      <div className="border-b-2 pb-2 mb-2">
        <div className="flex">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            <p className="mx-2">Estado:</p>
          </div>
          <p>{getMembershipStatus(subscription?.userSubscription?.status)}</p>
        </div>
      </div>

      {/* Duration */}
      <div className="border-b-2 pb-2 mb-2">
        <div className="flex">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
            <p className="mx-2">Duración:</p>
          </div>
          <p>30 días</p>
        </div>
      </div>

      {/* Period Start */}
      <div className="border-b-2 pb-2 mb-2">
        <div className="flex">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            <p className="mx-2">Inicio de Periodo:</p>
          </div>
          <p>
            {getMembershipDate(
              isPreordered
                ? subscription?.userSubscription?.current_period_end
                : subscription?.userSubscription?.current_period_start
            ).toDateString()}
          </p>
        </div>
      </div>

      {/* Period End */}
      {isActive && subscription?.userSubscription?.current_period_end && (
        <div className="border-b-2 pb-2 mb-2">
          <div className="flex">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
              <p className="mx-2">Fin de Periodo:</p>
            </div>
            <p>
              {getMembershipDate(subscription?.userSubscription?.current_period_end).toDateString()}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
