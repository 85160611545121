import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import Cards, { Focused } from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useContext, useEffect, useState } from 'react';
import { buttons, divs, inputs, texts } from '../../../constants/styles';
import { createUserPaymentCard, updatePaymentCard } from '../../../apis/paymentCards';
import { PaymentCard } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import { toast, ToastContainer } from 'react-toastify';
export default function AddPaymentCard() {
  //Required values to create a card
  const { currentUserData } = useContext(AuthContext);
  const [number, setNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');

  //Optional values to create a card
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [tag, setTag] = useState('');

  //Fields handlers and design control
  const [validValues, setValidValues] = useState(false);
  const [includeAdicional, setIncludeAdicional] = useState(false);
  const [focus, setFocus] = useState('' as Focused);

  //Fields validation to create a card
  useEffect(() => {
    if (number.length <= 16 && number.length >= 15 && expiry.length === 4 && cvc.length >= 3) {
      if (includeAdicional) {
        if (
          city.length > 0 &&
          postalCode.length > 0 &&
          address.length > 0 &&
          state.length > 0 &&
          tag.length > 0
        ) {
          setValidValues(true);
        } else {
          setValidValues(false);
        }
      } else {
        setValidValues(true);
      }
    } else {
      setValidValues(false);
    }
  }, [name, number, expiry, cvc, city, postalCode, address, state, tag, includeAdicional]);

  function createCard() {
    const newCard: PaymentCard = {
      exp_month: parseInt(expiry.slice(0, 2)),
      exp_year: parseInt(expiry.slice(2, 4)),
      number: number,
      cvc: cvc
    };
    createUserPaymentCard({ card: newCard, customerMail: currentUserData.email }).then((res) => {
      if (res) {
        setValidValues(false);
        if (includeAdicional) {
          updatePaymentCard({
            name: name,
            paymentMethodId: res.id,
            address: {
              city: city,
              line1: address,

              postal_code: postalCode,
              state: state
            },
            tag: tag
          }).then((res2) => {
            if (res2) {
              toast.success('Tarjeta agregada con éxito', { position: 'top-center' });
              setTimeout(() => {
                window.location.href = '/miPerfil/metodosDePago';
              }, 3000);
            } else {
              toast.error(
                'Ha ocurrido un error. Verifica que los datos de la tarjeta sean correctos.',
                {
                  position: 'top-center'
                }
              );
            }
          });
        } else {
          toast.success('Tarjeta agregada con éxito', { position: 'top-center' });
          setTimeout(() => {
            window.location.href = '/miPerfil/metodosDePago';
          }, 3000);
        }
      } else {
        toast.error('Ha ocurrido un error. Verifica que los datos de la tarjeta sean correctos.', {
          position: 'top-center'
        });
      }
    });
  }

  return (
    <TopBarScreen backRoute="../miPerfil/metodosDePago">
      <ToastContainer></ToastContainer>
      <div className={divs.content + ' '} id="PaymentForm">
        <Cards cvc={cvc} expiry={expiry} name={name} number={number} focused={focus} />
        <div className="m-8">
          <>
            <p className="text-gray-600 ml-1 mb-2">{'Número de tarjeta'}</p>
            <input
              onFocus={() => {
                setFocus('number');
              }}
              className={inputs.primaryInput}
              value={number}
              type="number"
              placeholder={'Número de tarjeta'}
              onChange={(e) => {
                if (e.target.value.length <= 16) {
                  setNumber(e.target.value);
                }
              }}
            />
          </>
          <>
            <p className="text-gray-600 ml-1 mb-2">{'Fecha de expiración'}</p>
            <input
              onFocus={() => {
                setFocus('expiry');
              }}
              className={inputs.primaryInput}
              value={expiry}
              type="number"
              placeholder={'Fecha de expiración'}
              onChange={(e) => {
                if (e.target.value.length <= 4) {
                  setExpiry(e.target.value);
                }
              }}
            />
          </>
          <>
            <p className="text-gray-600 ml-1 mb-2">{'CVC'}</p>
            <input
              onFocus={() => {
                setFocus('cvc');
              }}
              className={inputs.primaryInput}
              value={cvc}
              type="number"
              placeholder={'CVC'}
              onChange={(e) => {
                if (e.target.value.length <= 4) {
                  setCvc(e.target.value);
                }
              }}
            />
          </>
          <button
            onClick={() => {
              setIncludeAdicional(!includeAdicional);
            }}
            className={
              'rounded border-2  mt-10 mb-5 p-2 ' +
              (includeAdicional ? ' bg-primary ' : 'bg-gray-500')
            }>
            <p className={texts.primaryButonText}>
              {includeAdicional ? 'Quitar datos adicionales' : 'Agregar datos adicionales'}
            </p>
          </button>

          {/* Si el usuario decide agregar los valores adicionales, agregarlos */}
          {includeAdicional && (
            <div className="border-2 p-2 rounded-lg mb-4">
              <>
                <p className="text-gray-600 ml-1 mb-2">{'Nombre del titular de la tarjeta'}</p>
                <input
                  onFocus={() => {
                    setFocus('name');
                  }}
                  className={inputs.primaryInput}
                  value={name}
                  placeholder={'Nombre del titular de la tarjeta'}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </>
              <p className="text-xl">Dirección de la tarjeta</p>
              <>
                <p className="text-gray-600 ml-1 mb-2">{'Calle y número'}</p>
                <input
                  className={inputs.primaryInput}
                  value={address}
                  placeholder={'Calle y número'}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </>
              <>
                <p className="text-gray-600 ml-1 mb-2">{'Ciudad'}</p>
                <input
                  className={inputs.primaryInput}
                  value={city}
                  placeholder={'Ciudad'}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </>
              <>
                <p className="text-gray-600 ml-1 mb-2">{'Código Postal'}</p>
                <input
                  className={inputs.primaryInput}
                  value={postalCode}
                  placeholder={'Código Postal'}
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                />
              </>
              <>
                <p className="text-gray-600 ml-1 mb-2">{'Estado'}</p>
                <input
                  className={inputs.primaryInput}
                  value={state}
                  placeholder={'Estado'}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </>

              <>
                <p className="text-gray-600 ml-1 mb-2">{'Tag'}</p>
                <input
                  onFocus={() => {
                    setFocus('name');
                  }}
                  className={inputs.primaryInput}
                  value={tag}
                  placeholder={'Descripción de la dirección'}
                  onChange={(e) => {
                    setTag(e.target.value);
                  }}
                />
              </>
            </div>
          )}
          {validValues ? (
            <button
              onClick={() => {
                createCard();
              }}
              className={buttons.primaryButton}>
              <p className={texts.primaryButonText}>Guardar tarjeta</p>
            </button>
          ) : (
            <button className={buttons.disabledButton}>
              <p className={texts.primaryButonText}>Guardar tarjeta</p>
            </button>
          )}
        </div>
      </div>
    </TopBarScreen>
  );
}
