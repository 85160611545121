import { useState } from 'react';

import { Model } from '../models/interfaces';
import ImagePathConverter from '../utils/imagePathConverter';

interface CardProps {
  title: string;
  brand: string;
  price: number;
  imgBucketPath: string;
  modelId: string;
  model: Model;
}
export default function ModelCard({
  title,
  brand,
  price,
  imgBucketPath,
  modelId,
  model
}: CardProps) {
  // With the image path we will get the url. Initially, we will have a loading component. Once we have the URL, it will display it.
  const [imgUrlState, setImgUrlState] = useState('');

  if (imgBucketPath.length > 0) {
    ImagePathConverter(imgBucketPath).then((url) => {
      setImgUrlState(url);
    });
  }
  return (
    <div
      className="rounded w-[240px] mb-10 shadow-[0px_5px_15px_#f3f3f3] my-2 justify-self-center cursor-pointer hover:shadow-[0px_5px_15px_#dfdfdf] transition-all duration-300"
      onClick={() => {
        window.location.href = `/modelo/${modelId}`;
      }}>
      {imgUrlState === '' ? (
        <div className="h-[250px] w-full bg-grey"></div>
      ) : (
        <div className="flex justify-center ">
          <img className="h-[250px] w-full object-cover" src={imgUrlState}></img>
        </div>
      )}

      <div className="px-4 pt-3 pb-5 flex flex-col">
        <div className="flex flex-col justify-center">
          <p className="text-lg font-semibold h-[30px] overflow-hidden">{title}</p>
          <p className="text-red/90 mb-3">{brand}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[19px] text-primary">
            <span>{price.toLocaleString()}</span> créditos
          </p>
          {model.euros && (
            <p className="text-[15px] text-gray-400 mt-[3px] mr-2">
              {'Euros: €' + model.euros.toLocaleString()}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
