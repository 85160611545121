import { faEdit, faTrash, faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getUserPaymentCards, deleteUserPaymentCard } from '../../../apis/paymentCards';
import { divs, texts } from '../../../constants/styles';
import { StripePaymentCard } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import { RandomID } from '../../../utils/randomIDGenerator';
import { AlertDialog } from '../../AlertDialog';
import LoadingScreen from '../LoadingScreen';
import Cards from 'react-credit-cards-2';

export default function PaymentCardsView() {
  const { currentUserData } = useContext(AuthContext);
  const [cards, setCards] = useState<StripePaymentCard[]>([]);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleClick = () => navigate(`agregarTarjeta`);

  // Mode that only lets to select and go to another path
  const location = useLocation();
  const [selectMode] = useState(location.state?.selectMode);

  const [openDialog, setOpenDialog] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<StripePaymentCard>();

  useEffect(() => {
    getUserPaymentCards(currentUserData.email).then((res) => {
      if (res) setCards(res);
      setLoading(false);
    });
  }, [currentUserData]);

  function deleteCard() {
    if (cardToDelete) {
      deleteUserPaymentCard(cardToDelete.id).then((res) => {
        if (res) {
          setCards(cards.filter((card) => card.id !== cardToDelete.id));
          toast.success('Tarjeta eliminada', { position: 'top-center' });
        } else {
          toast.error('Error al eliminar tarjeta', { position: 'top-center' });
        }
      });
    }
  }

  return (
    <TopBarScreen backRoute="../miPerfil">
      {loading ? (
        <LoadingScreen></LoadingScreen>
      ) : (
        <div className={divs.content}>
          <ToastContainer></ToastContainer>
          <AlertDialog
            title="Eliminar tarjeta"
            description="¿Estás segura/o que quieres eliminar esta tarjeta?"
            agreeText="Eliminar"
            agreeAction={() => {
              deleteCard();
            }}
            agreeColor="text-red-600"
            disagreeText="Cancelar"
            setOpen={setOpenDialog}
            open={openDialog}></AlertDialog>
          <h1 className={texts.title}>Mis tarjetas</h1>
          {cards.length > 0 ? (
            cards.map((cardData) => {
              return (
                <div key={RandomID()} className="m-4 flex justify-center">
                  <div
                    onClick={
                      // If select mode, redirect when click
                      selectMode
                        ? () => {
                            navigate(location.state?.nextPath, {
                              state: {
                                selectedPaymenthMethod: cardData,
                                subscription: location.state?.subscription,
                                currentSubscription: location.state?.currentSubscription,
                                futureSubscription: location.state?.futureSubscription
                              }
                            });
                          }
                        : () => {}
                    }>
                    <Cards
                      preview
                      name={cardData.billing_details.name || 'Sin Nombre'}
                      cvc={'***'}
                      number={'************' + cardData.card.last4}
                      expiry={cardData.card.exp_month + cardData.card.exp_year}
                      issuer={cardData.card.brand}
                    />
                  </div>
                  {!selectMode && (
                    <div className=" flex flex-col">
                      <FontAwesomeIcon
                        onClick={() => navigate(`${cardData.id}`)}
                        className="text-primary text-3xl m-4 mt-[50%]"
                        icon={faEdit}></FontAwesomeIcon>
                      <FontAwesomeIcon
                        onClick={() => {
                          setCardToDelete(cardData);
                          setOpenDialog(true);
                        }}
                        className="text-red-600 text-3xl m-4  mt-[50%]"
                        icon={faTrash}></FontAwesomeIcon>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p className="text-center mb-[100px] ">No tienes tarjetas agregadas</p>
          )}

          {!selectMode && (
            <div>
              <FontAwesomeIcon
                onClick={handleClick}
                className="h-[20px] w-[20px] bg-primary rounded-full p-3 text-white ml-[75%] md:ml-[85%]"
                icon={faAdd}></FontAwesomeIcon>
            </div>
          )}
        </div>
      )}
    </TopBarScreen>
  );
}
