import { useState } from 'react';
import { TopBar } from '../../components/Topbar';
import Dashboard from './DashboardScreen';
import Footer from './Footer';

interface DashboardAndTopbarScreenProps {
  children: React.ReactNode;
  screen: string;
}
export default function DashboardAndTopbarScreen({
  children,
  screen
}: DashboardAndTopbarScreenProps) {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div className="flex flex-col max-w-[1920px]">
      <Dashboard
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        currentScreen={screen}></Dashboard>

      <div className="md:pl-64 flex flex-col flex-1">
        <TopBar
          openSideBar={() => {
            setShowSideBar(true);
          }}></TopBar>
        <div className="min-h-[70vh]">{children}</div>
        <Footer />
      </div>
    </div>
  );
}
