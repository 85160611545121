/*
This function recives an array of values and check if they are null or empty.
It is used to validate values that will be sent to the server
*/
export function validateInputs(values: Array<any>) {
  let status = true;
  values.forEach((value) => {
    if (value === '' || value === null) status = false;
  });
  return status;
}

/* 
This function recives an error or object and a default mesage, the function tries to find the 
error mesage in the error and if it can't find it it returns the default mesage.
It is used to show the error message when an API call fails
*/
export function getResponseError(error: any, defaultMsg: string) {
  if (error?.response?.data?.msg) return error?.response?.data?.msg;
  if (error?.data?.msg) return error?.data?.msg;
  return defaultMsg;
}

/* 
This function recives an API response and tries to find the status of the response.
*/
export function checkResponse(response: any) {
  let status = false;
  if (response?.data?.status === 'succes') status = true;
  if (response?.data?.status === 'sucess') status = true;
  if (response?.data?.status === 'success') status = true;
  if (response?.data?.stauts === 'success') status = true;
  return status;
}
