import AddressesView from '../../components/Views/Address/AddressesView';
import TopBarScreen from '../layouts/TopBarScreen';

export default function AddressesScreen() {
  return (
    <TopBarScreen backRoute="../miPerfil">
      <AddressesView></AddressesView>
    </TopBarScreen>
  );
}
