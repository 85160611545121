import axios from 'axios';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function getCategories() {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(`${url}/categories/get-categories-with-available-clothes`);
    return response.data.categories;
  } catch (error) {
    ManageErrorLog(error, 'Error in getCategories');
    return [];
  }
}

export async function getModelsByCategory(value: string) {
  try {
    const response = await axios.get(
      `${url}/clothes/category/${value}?filterAvailableClothes=true&filterAvailableSubcategories=true`
    );

    return response.data;
  } catch (error) {
    ManageErrorLog(error, 'Error in getModelsByCategory');
    return [];
  }
}

export async function getCategoryById(id: string) {
  try {
    const response = await axios.get(`${url}/categories`);
    const categoriesMap = new Map<string, string>();
    response.data.categories.forEach((v: { id: string; name: string }) => {
      categoriesMap.set(v.id, v.name);
    });
    return categoriesMap.get(id);
  } catch (error) {
    ManageErrorLog(error, 'Error in getCategoryById');
    return [];
  }
}
