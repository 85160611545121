import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../providers/Auth';

interface ProtectedRouteProps {
  children: any;
  redirectTo?: string;
}

export default function ProtectedRoute({ children, redirectTo }: ProtectedRouteProps) {
  const { currentUserData, currentUser } = useContext(AuthContext);

  const [isLogged, setIsLogged] = useState<boolean | null>(null);

  useEffect(() => {
    if (currentUserData === null || currentUserData === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [currentUser, currentUserData]);

  function Render() {
    if (isLogged === null) {
      return <div>Loading</div>;
    } else if (isLogged) {
      return children;
    } else {
      return <Navigate to={redirectTo || '/ingresar'} />;
    }
  }

  return <Render></Render>;
}
