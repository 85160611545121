import axios from 'axios';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export const createReview = async (
  reviewData: any,
  imgsData: { fileName: string; filePath: string }[] | null | undefined
) => {
  try {
    const body = {
      ...reviewData,
      imgs_data: imgsData
    };
    const response = await axios.post(`${url}/reviews/new`, body);
    return response;
  } catch (error) {
    ManageErrorLog(error, 'Error in createReview');
    return false;
  }
};

export const deleteReview = async (modelId: string, reviewId: string, userId: string) => {
  try {
    const response = await axios.delete(`${url}/reviews/delete/${modelId}/${reviewId}/${userId}`);
    return response;
  } catch (error) {
    ManageErrorLog(error, 'Error in deleteReview');
    return false;
  }
};
