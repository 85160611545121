import { Button, Rating, TextField } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { divs, texts } from '../../../constants/styles';
import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import ViewImages from '../../ViewImages';
import { toast, ToastContainer } from 'react-toastify';
import { validateInputs } from '../../../utils/requests';
import { AuthContext } from '../../../providers/Auth';
import { createReview } from '../../../apis/reviews';
import { uploadMultipleImages } from '../../../utils/images';

export default function AddReviewView() {
  const { currentUser, currentUserData } = React.useContext(AuthContext);
  const modelData = useLocation().state;
  const [rating, setRating] = React.useState<number | null>(3);
  const [title, setTitle] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [imgs, setImgs] = React.useState<Array<File> | null>([]);
  const inputRef = React.useRef(null);
  const lableStyle = 'text-primary text-base mb-2 font-semibold mt-[10px]';
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = React.useState(false);

  async function addReviewHandler() {
    setDisableBtn(true);

    // Validate fields
    if (!validateInputs([rating, title, comments])) {
      toast.error('Debes llenar todos los campos', { position: 'top-center' });
      setDisableBtn(false);
      return;
    }

    // Upload images and get refs
    let imgsData: { filePath: any; fileName: any }[] | null | undefined = null;
    if (imgs && imgs.length > 0) {
      const resUpload = await uploadMultipleImages(imgs);
      if (!resUpload.status) {
        toast.error(resUpload?.msg || 'Hubo un error subiendo las imágenes');
        setDisableBtn(false);
        return;
      }
      imgsData = resUpload?.fileRefs?.map((img) => ({
        filePath: img?.fullPath,
        fileName: img?.name
      }));
    }

    // Create review
    const reviewData = {
      model_id: modelData?.model_id,
      content: comments,
      score: rating,
      user_id: currentUser?.uid,
      title,
      created_at: new Date().toString(),
      user_img_path: currentUserData?.user_img
    };

    const resCreateReview = await createReview(reviewData, imgsData);

    if (!resCreateReview) {
      toast.error('Hubo un error creando la reseña', { position: 'top-center' });
      setDisableBtn(false);
      return;
    }

    // Go to model view
    setDisableBtn(false);
    navigate(`/home`);
  }

  return (
    <TopBarScreen backRoute={'../modelo/' + modelData?.model_id}>
      <div className={divs.content}>
        <h1 className={texts.title}>Agregar Reseña</h1>
        <h2 className="ml-8 text-[24px] font-semibold mt-[30px]">{modelData?.name}</h2>
        <h3 className="ml-8 text-[20px] text-gray-400">{modelData?.brand}</h3>
        <div className="flex justify-center">
          <img
            src={modelData?.model_imgs[0]?.url}
            alt="Modelo"
            className="w-[80%] max-h-[250px] object-contain"
          />
        </div>

        <div className="mt-[30px] px-8">
          <p className={lableStyle}>Calificación</p>
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            size="large"
          />
          <p className={lableStyle}>Título de la Reseña</p>
          <TextField
            variant="standard"
            fullWidth
            color="success"
            sx={{ mb: 2 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <p className={lableStyle}>Comentarios</p>
          <TextField
            variant="standard"
            multiline
            rows={3}
            fullWidth
            color="success"
            sx={{ mb: 2 }}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />

          <Button
            color="success"
            sx={{ mt: 2 }}
            onClick={() => {
              // @ts-ignore
              inputRef.current.click(); // Open file input
            }}>
            Agregar Imágenes
          </Button>

          {/* Input for images */}
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              if (imgs && e?.target?.files && e?.target?.files[0]) {
                setImgs([...imgs, e.target.files[0]]); // Add input image
              }
            }}
          />

          {/* Images preview */}
          <ViewImages imgs={imgs?.map((img: File) => URL.createObjectURL(img)) || []}></ViewImages>

          <div className="flex w-full justify-center">
            <Button
              onClick={addReviewHandler}
              sx={{ mt: 3, mb: 4 }}
              variant="contained"
              disabled={disableBtn}
              color="success">
              Añadir Reseña
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </TopBarScreen>
  );
}
