import { initializeApp } from 'firebase/app';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';
import AddAddressView from './components/Views/Address/AddAddressView';
import AddressView from './components/Views/Address/AddressView';
import AddReviewView from './components/Views/Models/AddReviewView';
import OrderDetails from './components/Views/Orders/OrderDetails';
import AddPaymentCard from './components/Views/Payment/AddPaymentCard';
import UpdatePaymentCard from './components/Views/Payment/UpdatePaymentCard';
import { firebaseConfig } from './firebaseConfig';
import { AuthProvider } from './providers/Auth';
import CartScreen from './screens/CartScreen';
import ProtectedRoute from './screens/layouts/ProtectedRoute';
import ModelScreen from './screens/Model/ModelScreen';
import ProductsScreen from './screens/ProductsScreen';
import AddressesScreen from './screens/profile/AddressesScreen';
import FavoritesScreen from './screens/profile/FavoritesScreen';
import MembershipScreen from './screens/profile/MembershipScreen';
import MembershipsScreen from './screens/profile/MembershipsScreen';
import OrdersScreen from './screens/profile/Orders';
import PaymentsScreen from './screens/profile/PaymentsScreen';
import ProfileScreen from './screens/profile/ProfileScreen';
import ProfileSettingsScreen from './screens/profile/ProfileSettingsScreen';
import SubcategoryScreen from './screens/SubcategoryScreen';
import LandingScreen from './screens/landing/LandingScreen';
import UsScreen from './screens/landing/UsScreen';
import ContactScreen from './screens/landing/ContactScreen';
import FAQScreen from './screens/landing/FAQScreen';
import BrandsScreen from './screens/landing/BrandsScreen';
import CookiesPolicyScreen from './screens/landing/CookiesPolicyScreen';
import PrivacyPolicyScreen from './screens/landing/PrivacyPolicyScreen';
import TermsAndConditionsScreen from './screens/landing/TermsAndConditionsScreen';
import Register from './screens/profile/Register';
import Login from './screens/profile/Login';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import PageNotFound from './screens/404';

const isProd = process.env.REACT_APP_IS_PROD || false;

function App() {
  initializeApp(firebaseConfig);
  if (isProd === 'true') {
    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGNSAG_API_KEY || '',
      plugins: [new BugsnagPluginReact()]
    });
    const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
    if (ErrorBoundary) {
      return (
        <ErrorBoundary>
          <AppContainer></AppContainer>
        </ErrorBoundary>
      );
    } else {
      return <AppContainer></AppContainer>;
    }
  } else {
    return <AppContainer></AppContainer>;
  }
}

function AppContainer() {
  return (
    <AuthProvider>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: '/',
            element: <LandingScreen></LandingScreen>,
            errorElement: <PageNotFound></PageNotFound>
          },
          {
            path: '/nosotros',
            element: <UsScreen></UsScreen>
          },
          {
            path: '/contacto',
            element: <ContactScreen></ContactScreen>
          },
          {
            path: '/marcas',
            element: <BrandsScreen></BrandsScreen>
          },
          {
            path: '/preguntas-frecuentes',
            element: <FAQScreen></FAQScreen>
          },
          {
            path: '/politica-privacidad',
            element: <PrivacyPolicyScreen></PrivacyPolicyScreen>
          },
          {
            path: '/terminos-condiciones',
            element: <TermsAndConditionsScreen></TermsAndConditionsScreen>
          },
          {
            path: '/politica-cookies',
            element: <CookiesPolicyScreen></CookiesPolicyScreen>
          },
          {
            path: '/home',
            element: <ProductsScreen></ProductsScreen>
          },
          {
            path: '/carrito',
            element: <CartScreen></CartScreen>
          },
          {
            path: '/miPerfil',
            element: (
              <ProtectedRoute>
                <ProfileScreen />
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/ajustes',
            element: (
              <ProtectedRoute>
                <ProfileSettingsScreen></ProfileSettingsScreen>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/direcciones',
            element: (
              <ProtectedRoute>
                <AddressesScreen></AddressesScreen>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/direcciones/agregarDireccion',
            element: (
              <ProtectedRoute>
                <AddAddressView></AddAddressView>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/direcciones/direccion',
            element: (
              <ProtectedRoute>
                <AddressView></AddressView>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/metodosDePago',
            element: (
              <ProtectedRoute>
                <PaymentsScreen></PaymentsScreen>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/metodosDePago/agregarTarjeta',
            element: (
              <ProtectedRoute>
                <AddPaymentCard></AddPaymentCard>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/metodosDePago/:id',
            element: (
              <ProtectedRoute>
                <UpdatePaymentCard></UpdatePaymentCard>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/pedidos',
            element: (
              <ProtectedRoute>
                <OrdersScreen></OrdersScreen>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/pedidos/:id',
            element: (
              <ProtectedRoute>
                <OrderDetails></OrderDetails>
              </ProtectedRoute>
            )
          },
          {
            path: '/miPerfil/membresias',
            element: <MembershipsScreen></MembershipsScreen>
          },
          {
            path: '/miPerfil/membresias/membresia',
            element: <MembershipScreen></MembershipScreen>
          },
          {
            path: '/miPerfil/Favoritos',
            element: (
              <ProtectedRoute>
                <FavoritesScreen></FavoritesScreen>
              </ProtectedRoute>
            )
          },
          {
            path: '/subcategoria/:nombre',
            element: <SubcategoryScreen></SubcategoryScreen>
          },
          {
            path: '/modelo/:nombre',
            element: <ModelScreen></ModelScreen>
          },
          {
            path: '/modelo/:nombre/agregarResena',
            element: <AddReviewView></AddReviewView>
          },
          {
            path: '/registro',
            element: <Register></Register>
          },
          {
            path: '/ingresar',
            element: <Login></Login>
          }
        ])}></RouterProvider>
    </AuthProvider>
  );
}

export default App;
