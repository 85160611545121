import React from 'react';
import { IconButton } from '@mui/material';
import InfoScreen from '../layouts/InfoScreen';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function PrivacyPolicyScreen() {
  const nImages = 9;
  const fileNames = Array.from(Array(nImages).keys()).map((i) => `/files/policy/${i + 1}.jpg`);
  const [fileIndex, setFileIndex] = React.useState(0);

  return (
    <InfoScreen title="Política de Privacidad">
      <div className="flex w-full items-center flex-col">
        <div className="flex items-center mb-6">
          <IconButton
            onClick={() => {
              if (fileIndex > 0) setFileIndex(fileIndex - 1);
            }}
            color="primary"
            size="small">
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          <p className="mb-[3px] mx-4 text-[17px]">
            Página {fileIndex + 1} / {nImages}
          </p>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              if (fileIndex < nImages - 1) setFileIndex(fileIndex + 1);
            }}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="md:w-[70%] w-[95%]">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xerfer-pruebas.appspot.com/o/media%2Fprivacy.pdf?alt=media&token=6bb0dc24-8dc5-4e08-8fbe-b6bb7ee51792"
            target="_blank"
            rel="noreferrer">
            <img
              src={fileNames[fileIndex]}
              alt="Términos y Condiciones"
              className="shadow-[0_10px_30px_-10px_rgba(0,0,0,0.15)]"
            />
          </a>
        </div>
      </div>
    </InfoScreen>
  );
}
