import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useState } from 'react';

export default function OrderStepper({ curStep }: { curStep: string }) {
  const [steps, setSteps] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);

  function createSteps() {
    const setpLabels = [
      'Confirmando',
      'En Camino',
      'Entregado',
      'Preparando Devolución',
      'Devuelto'
    ];

    // Find stepper index
    let stepperIndex = 0;
    if (curStep === 'assigning_worker' || curStep === 'preparing_order') stepperIndex = 0;
    else if (curStep === 'order_shipped') stepperIndex = 1;
    else if (curStep === 'order_with_client') stepperIndex = 2;
    else if (curStep === 'preparing_devolution') stepperIndex = 3;
    else if (curStep === 'pedido_en_almacen') stepperIndex = 4;
    else if (curStep === 'order_in_store') stepperIndex = 4;
    setActiveStep(stepperIndex);

    // Create step values
    const stepsData = [];
    for (let i = 0; i < setpLabels.length; i++) {
      const stepData = {
        label: setpLabels[i],
        index: i,
        completed: i < stepperIndex
      };
      stepsData.push(stepData);
    }

    setSteps(stepsData);
  }

  React.useEffect(() => {
    createSteps();
  }, [curStep]);
  if (curStep === 'order_canceled') {
    return (
      <div className="text-center bg-red-400 text-white py-3 text-3xl">
        El Pedido ha sido cancelado
      </div>
    );
  }

  return (
    <div>
      <Stepper orientation="horizontal" alternativeLabel activeStep={activeStep}>
        {steps.map((step: any) => (
          <Step key={step.index} completed={step.completed} index={step.index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
