import { useContext, useEffect, useState } from 'react';
import { getUserCart, updateUser, UpdateUserProps } from '../apis/users';
import { AuthContext } from '../providers/Auth';
import DashboardAndTopbarScreen from './layouts/DashboardAndTopbarScreen';
import { RandomID } from '../utils/randomIDGenerator';
import CartItem from '../components/CartItem';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { createOrder, CreateOrderProps } from '../apis/orders';
import { AlertDialog } from '../components/AlertDialog';
import LoadingScreen from '../components/Views/LoadingScreen';

export default function CartScreen() {
  const { currentUser, currentUserData } = useContext(AuthContext);
  const [selectedAddress] = useState(useLocation().state?.selectedAddress);
  const currentPath = useLocation()?.pathname;
  const [cart, setCart] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [returnDateString, setReturnDateString] = useState<string>('');

  // This first load is for controlling getting the cart data. If we don't do this, it will be called more than once
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    redirectUserToProfileIfNotLoggedIn();
    loadUserCart();
  }, [currentUserData]);

  useEffect(() => {
    const returnDateText = handleReturnDate();
    setReturnDateString(returnDateText);
    getTotalCreditsInCart();
  }, [cart]);

  function redirectUserToProfileIfNotLoggedIn() {
    if (currentUser === null) {
      window.location.href = '../miPerfil';
    }
  }

  function loadUserCart() {
    if (firstLoad) {
      getUserCart(currentUser?.uid).then((res) => {
        if (!res) {
          toast.error('Hubo un error al obtener el carrito', {
            position: 'top-center'
          });
        } else {
          setCart(res);
          setLoading(false);
        }
      });
      setFirstLoad(false);
    }
  }

  function handleReturnDate() {
    const newDate = new Date();

    const productOutOfStock = hasOutOfStockProduct();
    calculateReturnDate(productOutOfStock);

    const returnDateText = setReturnDateText();
    return returnDateText;

    function hasOutOfStockProduct() {
      let anyProductNotInStock = false;
      cart.forEach((element) => {
        if (!element.product_in_stock) {
          anyProductNotInStock = true;
        }
      });
      return anyProductNotInStock;
    }

    function calculateReturnDate(anyProductNotInStock: boolean) {
      if (anyProductNotInStock) {
        // If there is a product that is not in stock, the return date will be 5 days after
        newDate.setDate(newDate.getDate() + 5);
      }
    }

    function setReturnDateText() {
      const day = newDate.getDate();

      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();

      if (month === 12) {
        year++;
      } else {
        month++;
      }

      const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ];

      const text = ` ${day} de  ${months[month - 1]}, ${year}`;

      return text;
    }
  }

  function getTotalCreditsInCart() {
    let total = 0;
    cart.forEach((item) => {
      total += item?.data?.price;
    });
    setTotal(total);
  }

  async function removeCartItem(item: any) {
    let updatedCart: any[] = [];
    removeItemFromLocalCart();
    await removeItemFromDB();

    setCart(updatedCart);

    function removeItemFromLocalCart() {
      let founded = false;
      cart.forEach((cartItem) => {
        if (cartItem?.model_id === item?.model_id && cartItem?.size === item?.size && !founded) {
          founded = true;
        } else {
          updatedCart = [...updatedCart, cartItem];
        }
      });
    }

    async function removeItemFromDB() {
      const updatedUserCart = updatedCart.map((item) => ({
        model_id: item?.model_id,
        size: item?.size
      }));
      const updatedUser: UpdateUserProps = {
        ...currentUserData,
        id: currentUser.uid,
        user: currentUserData,
        cart: updatedUserCart
      };
      await updateUser(updatedUser);
    }
  }

  async function handleOpenDialog() {
    if (cart.length <= 0) {
      toast.error('El carrito está vacío', { position: 'top-center' });
      return;
    }
    if (!selectedAddress) {
      toast.error('No has seleccionado una dirección de envío', { position: 'top-center' });
      return;
    }

    setOpenDialog(true);
  }

  async function createNewOrder() {
    const products = modifyProductsDataForUserOrder();
    const order = modifyOrderDataForUserOrder();

    const res: any = await createOrder(order);

    // If not succeeded, some products are not available.
    if (!res.success) {
      verifyProductsAvailability(res);
      return;
    }

    handleSucceededOrder();

    function modifyProductsDataForUserOrder() {
      const products = cart.map((item) => ({
        model: item?.model_id,
        size: item?.size
      }));
      return products;
    }

    function modifyOrderDataForUserOrder() {
      const order: CreateOrderProps = {
        address: selectedAddress,
        total_credits: total,
        user_id: currentUser?.uid,
        products
      };
      return order;
    }

    function handleSucceededOrder() {
      setCart([]);
      toast.success('Pedido realizado', { position: 'top-center' });
      setOpenDialog(false);
      navigate('/miPerfil/pedidos');
    }
  }

  // Verify what model and size is not available
  function verifyProductsAvailability(res: any) {
    const unavailable_products = res.unavailable_products;

    const newCart: any[] = [];
    getUnavailableProducts();
    setCart(newCart);
    toast.error(res.msg, { position: 'top-center' });

    function getUnavailableProducts() {
      cart.map((item) => {
        const newItem = item;
        unavailable_products.map((u_item: any) => {
          if (u_item.size === item.size && item.model_id === u_item.model) {
            newItem.available = false;
          }
        });
        newCart.push(newItem);
      });
    }
  }

  function verifyUserAvailability() {
    let available = true;
    if (currentUserData?.credits < total) {
      available = false;
    }
    if (!currentUserData?.available_orders) {
      available = false;
    }
    if (!currentUserData?.available_returns) {
      available = false;
    }
    if (currentUserData?.available_orders <= 0) {
      available = false;
    }
    if (currentUserData?.available_returns <= 0) {
      available = false;
    }

    return available;
  }

  return (
    <DashboardAndTopbarScreen screen="carrito">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <AlertDialog
            disagreeAction={() => {
              setOpenDialog(false);
            }}
            disagreeText="Cancelar"
            agreeText="Aceptar"
            agreeAction={() => {
              createNewOrder();
            }}
            description={
              'La devolución se programará para el' +
              returnDateString +
              ', si deseas cambiar la fecha, puedes contactar a soporte dentro del pedido'
            }
            setOpen={() => {}}
            open={openDialog}
            title="¿Seguro/a que quieres realizar el pedido?"></AlertDialog>
          <div className="flex justify-center w-full">
            <div
              className="w-[95%] p-4 md:grid md:gap-5 md:mt-7 flex flex-col"
              style={{ gridTemplateColumns: '2fr 1fr' }}>
              {/* Products */}
              <div className="shadow-lg rounded py-2">
                <h1 className="text-2xl font-semibold m-4 ml-5">Mi Carrito</h1>

                <div className="w-full px-4 mb-8">
                  {cart.map((item: any) => (
                    <div key={RandomID()}>
                      <CartItem
                        available={item.available == false ? false : true}
                        item={item}
                        removeCartItem={removeCartItem}
                        onlyView={false}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Checkout */}
              <div className="shadow-lg rounded py-2 mt-5 md:mt-0 md:mb-0 mb-10">
                <div className="w-full h-[400px] flex flex-col p-6">
                  <p className="text-primary text-2xl mb-3 font-semibold">Total</p>
                  <p className="font-semibold text-xl mb-9">Créditos: {total.toFixed(2)}</p>

                  <p className="text-primary text-2xl mb-3 font-semibold">Dirección</p>
                  {selectedAddress ? (
                    <p className="font-semibold text-lg mb-9">{`${selectedAddress?.street}, ${selectedAddress?.exterior_number}, ${selectedAddress?.postal_code}`}</p>
                  ) : (
                    <></>
                  )}

                  <div className="mb-9">
                    <Button
                      color="success"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate(`/miPerfil/direcciones`, {
                          state: { selectAdressMode: true, nextPath: currentPath }
                        });
                      }}>
                      {selectedAddress ? 'Cambiar dirección' : 'Agregar dirección'}
                    </Button>
                  </div>
                  {/* Verify user has enough credits */}
                  {verifyUserAvailability() ? (
                    <Button variant="contained" color="success" onClick={handleOpenDialog}>
                      Realizar Pedido
                    </Button>
                  ) : (
                    <div className="w-full">
                      <p className="text-red mb-[30px] font-semibold w-full">
                        NO tienes los créditos suficientes, o no tienes un envío para usar
                      </p>
                      <Button className="m-4" variant="contained" color="error" disabled fullWidth>
                        Realizar Pedido
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </DashboardAndTopbarScreen>
  );
}
