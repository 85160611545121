import { motion } from 'framer-motion';
import { colors } from '../../constants/colors';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

interface MobileSideBarProps {
  setShowSideBar(flag: boolean): void;
  showSideBar: boolean;
  screen: string;
}

interface DashboardProps {
  setShowSideBar(flag: boolean): void;
  showSideBar: boolean;
  currentScreen: string;
}

export default function Dashboard({ setShowSideBar, showSideBar, currentScreen }: DashboardProps) {
  if (window.innerWidth < 640) {
    return (
      <MobileSideBar
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
        screen={currentScreen}></MobileSideBar>
    );
  } else {
    return <DesktopSideBar screen={currentScreen}></DesktopSideBar>;
  }
}
export function MobileSideBar({ setShowSideBar, showSideBar, screen }: MobileSideBarProps) {
  return (
    <div className="relative z-40 md:hidden" role="dialog" aria-modal="true">
      {showSideBar ? (
        <div>
          <div>
            <div className={' bg-gray-600 bg-opacity-75 fixed inset-0'}></div>

            <div className={'flex z-40 fixed inset-0'}>
              <div
                style={{ backgroundColor: colors.grey }}
                className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-grey">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Close sidebar</span>

                    <svg
                      onClick={() => setShowSideBar(false)}
                      className="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="space-y-1">
                    <a
                      href="/home"
                      className={
                        (screen === 'home' ? 'bg-primary ' : '') +
                        'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
                      }>
                      <HomeOutlinedIcon
                        sx={{ mr: 2, ml: 1, fontSize: '25px' }}
                        className={screen === 'home' ? 'text-white' : 'text-primary'}
                      />
                      <p
                        className={
                          screen === 'home'
                            ? 'text-white font-[500] text-[16px]'
                            : 'text-black font-[500] text-[16px]'
                        }>
                        Productos
                      </p>
                    </a>

                    <a
                      href="/carrito"
                      className={
                        (screen === 'carrito' ? 'bg-primary ' : '') +
                        'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
                      }>
                      <ShoppingCartOutlinedIcon
                        sx={{ mr: 2, ml: 1, fontSize: '23px' }}
                        className={screen === 'carrito' ? 'text-white' : 'text-primary'}
                      />
                      <p
                        className={
                          screen === 'carrito'
                            ? 'text-white font-[500] text-[16px]'
                            : 'text-black font-[500] text-[16px]'
                        }>
                        Mi Carrito
                      </p>
                    </a>

                    <a
                      href="/miPerfil"
                      className={
                        (screen === 'miPerfil' ? 'bg-primary ' : '') +
                        'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
                      }>
                      <AccountCircleOutlinedIcon
                        sx={{ mr: 2, ml: 1, fontSize: '23px' }}
                        className={screen === 'miPerfil' ? 'text-white' : 'text-primary'}
                      />
                      <p
                        className={
                          screen === 'miPerfil'
                            ? 'text-white font-[500] text-[16px]'
                            : 'text-black font-[500] text-[16px]'
                        }>
                        Mi Perfil
                      </p>
                    </a>
                  </nav>
                </div>
              </div>

              <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          onAnimationEnd={() => {
            setShowSideBar(false);
            showSideBar = false;
          }}>
          <div className={' bg-gray-600 bg-opacity-75 '}></div>
        </motion.div>
      )}
    </div>
  );
}

export function DesktopSideBar({ screen }: { screen: string }) {
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div
        style={{ backgroundColor: colors.grey }}
        className="flex flex-col flex-grow pt-5  overflow-y-auto">
        <div className="mt-5 flex-1 flex flex-col">
          <nav className="flex-1 pb-4 space-y-1">
            <a
              href="/home"
              className={
                (screen === 'home' ? 'bg-primary ' : '') +
                'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
              }>
              <HomeOutlinedIcon
                sx={{ mr: 2, ml: 1, fontSize: '25px' }}
                className={screen === 'home' ? 'text-white' : 'text-primary'}
              />
              <p
                className={
                  screen === 'home'
                    ? 'text-white font-[500] text-[16px]'
                    : 'text-black font-[500] text-[16px]'
                }>
                Productos
              </p>
            </a>

            <a
              href="/carrito"
              className={
                (screen === 'carrito' ? 'bg-primary ' : '') +
                'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
              }>
              <ShoppingCartOutlinedIcon
                sx={{ mr: 2, ml: 1, fontSize: '23px' }}
                className={screen === 'carrito' ? 'text-white' : 'text-primary'}
              />
              <p
                className={
                  screen === 'carrito'
                    ? 'text-white font-[500] text-[16px]'
                    : 'text-black font-[500] text-[16px]'
                }>
                Mi Carrito
              </p>
            </a>

            <a
              href="/miPerfil"
              className={
                (screen === 'miPerfil' ? 'bg-primary ' : '') +
                'hover:bg-secondary group flex items-center px-2 py-[12px] transition-all duration-200'
              }>
              <AccountCircleOutlinedIcon
                sx={{ mr: 2, ml: 1, fontSize: '23px' }}
                className={screen === 'miPerfil' ? 'text-white' : 'text-primary'}
              />
              <p
                className={
                  screen === 'miPerfil'
                    ? 'text-white font-[500] text-[16px]'
                    : 'text-black font-[500] text-[16px]'
                }>
                Mi Perfil
              </p>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
