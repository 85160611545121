import React, { useRef, useState } from 'react';
import { Checkbox } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { createUser, getUser, verifyEmailInUse } from '../../apis/users';
import DashboardAndTopbarScreen from '../layouts/DashboardAndTopbarScreen';
import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../../firebaseConfig';
import { updateEmail } from 'firebase/auth';
import { AuthContext } from '../../providers/Auth';

export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [stepVerifyOTP, setStepVerifyOTP] = useState(false);
  const [disableFirstButton, setDisableFirstButton] = useState(false);
  const [disableSecondButton, setDisableSecondButton] = useState(false);
  const otpInputRef = useRef(null);
  const { setCurrentUser, setCurrentUserData } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const generateReCaptha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'reCaptachaContainer',
      {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      },
      auth
    );
  };

  const requestOTP = async () => {
    // Disable button
    setDisableFirstButton(true);

    // Verify email is not in use
    const emailInUse: boolean = await verifyEmailInUse(email);
    if (emailInUse) {
      setDisableFirstButton(false);
      toast.error('El email ya ha sido usado', { position: 'top-center' });
      return;
    }

    // Recaptcha
    generateReCaptha();

    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success('Se ha enviado un código a tu telefono', { position: 'top-center' });
        setStepVerifyOTP(true);

        // Scroll and focus OTP input
        setTimeout(() => {
          // @ts-ignore
          otpInputRef?.current?.focus();
          // @ts-ignore
          otpInputRef?.current?.scrollIntoView();
          window.scrollBy(0, -160);
        }, 200);
      })
      .catch((error) => {
        setDisableFirstButton(false);
        toast.error(
          'Has intentado más de una vez, o el número es inválido. Vuelve a cargar la página',
          { position: 'top-center' }
        );
        console.log(error);
      });
  };

  async function createNewAccount() {
    const user = auth.currentUser;

    // Verify auth user
    if (!user || !user?.uid) {
      toast.error('Ha ocurrido un error al crear tu cuenta', { position: 'top-center' });
      setCurrentUser(null);
      setCurrentUserData(null);
      auth.signOut();
      return;
    }

    // Create user in database
    const resDB = await createUser(name, email, phoneNumber, user?.uid);
    if (!resDB) {
      toast.error('Ha ocurrido un error al crear tu cuenta', { position: 'top-center' });
      setCurrentUser(null);
      setCurrentUserData(null);
      auth.signOut();
    }

    // Update auth user
    await updateEmail(user, email);

    // Success
    toast.success('Cuenta creada con éxito', { position: 'top-center' });

    // Get user data and set it in context
    const userData = await getUser(user?.uid);
    setCurrentUser(user);
    setCurrentUserData(userData.data);

    setTimeout(() => {
      navigate('/miPerfil');
    }, 500);
  }

  const verifyOTP = () => {
    setDisableSecondButton(true);

    window.confirmationResult
      .confirm(OTP)
      .then((result: any) => {
        // New auth user will be created, or it will login with a existing one
        const user = result.user;

        // If the auth account email is different from null, its because its an existing account
        if (user.email) {
          toast.error('Ya hay una cuenta con ese número de teléfono', { position: 'top-center' });
          setCurrentUser(null);
          setCurrentUserData(null);
          auth.signOut();
          return;
        } else {
          createNewAccount();
        }
      })
      .catch((error: any) => {
        console.log('ERROR', error);
        toast.error('El código de verificación es incorrecto', { position: 'top-center' });
        setDisableSecondButton(false);
        // User couldn't sign in (bad verification code?)
      });
  };

  return (
    <DashboardAndTopbarScreen screen="miPerfil">
      <div className="my-[10vh]">
        <div className="rounded-lg shadow-xl  max-w-[500px] relative mx-auto pb-8">
          <p className="text-3xl mb-10 text-center font-bold text-primary">Regístrate</p>
          <div className="mx-4 mb-6">
            <p className="font-semibold mb-2">Nombre</p>
            <input
              value={name}
              disabled={stepVerifyOTP}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="rounded border-2 w-full p-2"
              placeholder="Antonio García"></input>
          </div>
          <div className="mx-4 mb-6">
            <p className="font-semibold mb-2">Correo Electrónico</p>
            <input
              type="email"
              value={email}
              disabled={stepVerifyOTP}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="rounded border-2 w-full p-2"
              placeholder="antonio@email.com"></input>
          </div>
          <div className="mx-4">
            <p className="font-semibold mb-2">Número Celular</p>
            <input
              value={phoneNumber}
              disabled={stepVerifyOTP}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="rounded border-2 w-full p-2 mb-8"
              type="tel"
              placeholder="+34 3792839283"></input>
          </div>
          {stepVerifyOTP && (
            <div className="mx-4" id="otp-input">
              <p className="font-semibold mb-2">Código de Verificación</p>
              <input
                value={OTP}
                ref={otpInputRef}
                onChange={(e) => {
                  setOTP(e.target.value);
                }}
                className="rounded border-2 w-full p-2 mb-8"
                type="tel"
                placeholder="123456"></input>
            </div>
          )}

          {!stepVerifyOTP && (
            <div className="mx-3 mb-10">
              <div className="flex items-center">
                <Checkbox
                  size="small"
                  value={termsAccepted}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTermsAccepted(event.target.checked)
                  }
                />
                <p className="mb-[1px] text-[14px]">
                  Aceptar{' '}
                  <Link to="/terminos-condiciones" className="text-primary underline">
                    Términos y Condiciones
                  </Link>
                </p>
              </div>
              <div className="flex items-center">
                <Checkbox
                  size="small"
                  value={privacyAccepted}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPrivacyAccepted(event.target.checked)
                  }
                />
                <p className="mb-[1px] text-[14px]">
                  Aceptar{' '}
                  <Link to="/politica-privacidad" className="text-primary underline">
                    Política de Privacidad
                  </Link>
                </p>
              </div>
            </div>
          )}

          {/* First step button: Request OTP */}
          {!stepVerifyOTP ? (
            <div className="mx-4">
              <button
                disabled={
                  name.length < 1 ||
                  email.length < 1 ||
                  phoneNumber.length < 1 ||
                  !termsAccepted ||
                  !privacyAccepted ||
                  disableFirstButton
                }
                onClick={requestOTP}
                className={
                  'shadow rounded  w-full mb-8 p-2 ' +
                  (!(name.length < 1 || email.length < 1 || !termsAccepted || !privacyAccepted)
                    ? ' bg-primary'
                    : ' bg-gray-300')
                }>
                <p className="text-white font-semibold">Continuar</p>
              </button>
            </div>
          ) : (
            // Second step button: Verify OTP
            <div className="mx-4 my-3">
              <button
                disabled={
                  name.length < 1 ||
                  email.length < 1 ||
                  phoneNumber.length < 1 ||
                  !termsAccepted ||
                  !privacyAccepted ||
                  disableSecondButton
                }
                className={
                  'shadow rounded  w-full mb-8 p-2 ' +
                  (!(
                    name.length < 1 ||
                    email.length < 1 ||
                    phoneNumber.length < 1 ||
                    !termsAccepted ||
                    !privacyAccepted
                  )
                    ? ' bg-primary'
                    : ' bg-gray-300')
                }
                onClick={verifyOTP}>
                <p className="text-white font-semibold">Confirmar Código</p>
              </button>
            </div>
          )}

          <Link to="/ingresar">
            <p className="mx-5 text-primary underline text-[14px]">
              ¿Ya tienes cuenta? Inicia Sesión
            </p>
          </Link>
        </div>
        <div id="reCaptachaContainer"></div>
      </div>

      <ToastContainer />
    </DashboardAndTopbarScreen>
  );
}
