import React from 'react';

import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
interface ViewImagesProps {
  imgs: Array<string>;
}
export default function ViewImages({ imgs }: ViewImagesProps) {
  return (
    <div className={'w-full mb-[50px] max-w-[900px] flex flex-col items-center'}>
      <div className={'flex md:w-full w-[95%] justify-center'}>
        <Gallery>
          <div
            className={
              ' md:w-[500px] lg:w-[700px] w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[15px] mt-[20px]'
            }>
            {imgs?.map((img) => (
              <div className={'w-full relative'} key={img}>
                <Item
                  original={img}
                  thumbnail={img}
                  height="700"
                  width="600"
                  alt="Imágen del Modelo">
                  {({ ref, open }) => (
                    <img
                      style={{ cursor: 'pointer' }}
                      src={img}
                      ref={ref as React.MutableRefObject<HTMLImageElement>}
                      onClick={open}
                      alt="Imágen del Modelo"
                      className={'w-full h-full object-cover object-top shadow-bs4'}
                    />
                  )}
                </Item>
              </div>
            ))}
          </div>
        </Gallery>
      </div>
    </div>
  );
}
