import { ToastContainer, toast } from 'react-toastify';
import { buttons, inputs, texts } from '../../../constants/styles';
import { useEffect, useState } from 'react';
import { addEmailToAwaitingList } from '../../../apis/users';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';

export default function AwaitingList() {
  const [enail, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  useEffect(() => {
    if (enail.includes('@') && enail.includes('.')) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [enail]);

  async function addToAwaitingList() {
    addEmailToAwaitingList(enail).then((res) => {
      if (res) {
        toast.success('¡Gracias por registrarte!', { position: 'top-center' });
      } else {
        toast.error('Tu correo ya ha sido registrado', { position: 'top-center' });
      }
    });
  }

  return (
    <div className="mb-[15vh]">
      <ToastContainer></ToastContainer>

      <div className="flex flex-col items-center">
        <p className={texts.title + ' px-4 '}>
          ¡Falta muy poco para el lanzamiento! ¿Estás listo para la revolución de la moda?
        </p>
        <p className={texts.title + ' text-lg text-black my-8'}>Sé el primero en enterarte aquí</p>
        <div className="flex flex-col items-center md:w-[50%] w-[80%]">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Correo electrónico"
            className={inputs.primaryInput}></input>

          <div className="flex flex-col items-start mb-10 w-full px-[3%]">
            <div className="flex items-center">
              <Checkbox
                size="small"
                value={termsAccepted}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTermsAccepted(event.target.checked)
                }
              />
              <p className="mb-[1px] text-[14px]">
                Aceptar{' '}
                <Link to="/terminos-condiciones" className="text-primary underline">
                  Términos y Condiciones
                </Link>
              </p>
            </div>
            <div className="flex items-center">
              <Checkbox
                size="small"
                value={privacyAccepted}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPrivacyAccepted(event.target.checked)
                }
              />
              <p className="mb-[1px] text-[14px]">
                Aceptar{' '}
                <Link to="/politica-privacidad" className="text-primary underline">
                  Política de Privacidad
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className=" md:w-[50%] w-[80%]">
          {validEmail && termsAccepted && privacyAccepted ? (
            <button
              onClick={() => {
                addToAwaitingList();
              }}
              className={buttons.primaryButton}>
              <p className={texts.primaryButonText}>Regístrame</p>
            </button>
          ) : (
            <button className={buttons.disabledButton}>
              <p className={texts.primaryButonText}>Regístrame</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
