import axios, { AxiosError } from 'axios';
import { Subscription, UserSubscription } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';
import { getStripe } from '../utils/stripeConfig';

const url = process.env.REACT_APP_SERVER_URL;

export const getAllSubscriptions = async () => {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(`${url}/subscriptions`);
    return response.data.subscriptions as Subscription[];
  } catch (error) {
    ManageErrorLog(error, 'Error in getAllSubscriptions');
    return false;
  }
};

export const getUserActiveSubscriptions = async (id: string) => {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(`${url}/subscriptions/user/${id}?active=true`);
    const subscriptions: UserSubscription[] = response.data.subscriptions;

    subscriptions.forEach((subscription) => {
      response.data.stripe_subscriptions.forEach((stripe_subscription: any) => {
        if (subscription.stripe_subscription_id === stripe_subscription.id) {
          subscription.current_period_end = stripe_subscription.current_period_end;
          subscription.current_period_start = stripe_subscription.current_period_start;
        }
      });
    });

    return response.data.subscriptions as UserSubscription[];
  } catch (error) {
    ManageErrorLog(error, 'Error in getUserActiveSubscriptions');
    return false;
  }
};

interface ErrorResponse {
  error: string;
  clientSecret?: string;
}

export async function buySubscription(
  userId: string,
  methodId: string,
  subscriptionId: string,
  stripePaymentIntentId?: string
): Promise<boolean> {
  try {
    const body = {
      user_id: userId,
      payment_method_id: methodId,
      subscription_id: subscriptionId,
      stripe_payment_intent_id: stripePaymentIntentId
    };

    await axios.post(`${url}/subscriptions/user/create`, body);
    return true;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;

    if (axiosError.response?.data.error === 'requires_action') {
      return await handleSecureStripePayment(axiosError.response?.data.clientSecret || '');
    } else {
      ManageErrorLog(error, 'Error in buySubscription');
      return false;
    }
  }
  async function handleSecureStripePayment(clientSecret: string) {
    if (!clientSecret) {
      ManageErrorLog(new Error('Client secret is missing'), 'Error handling stripe card action');
      return false;
    }

    const stripe = await getStripe();
    const paymentIntentResult = await stripe?.retrievePaymentIntent(clientSecret);

    if (!paymentIntentResult || paymentIntentResult.error) {
      ManageErrorLog(paymentIntentResult?.error, 'Error retrieving PaymentIntent');
      return false;
    }

    const paymentIntent = paymentIntentResult.paymentIntent;
    if (paymentIntent.status === 'requires_confirmation') {
      const paymentActionResult = await stripe?.confirmCardPayment(clientSecret);

      if (paymentActionResult?.error) {
        ManageErrorLog(paymentActionResult?.error, 'Error confirming card payment');
        return false;
      } else {
        const stripePaymentIntentId = paymentActionResult?.paymentIntent.id;
        return await buySubscription(userId, methodId, subscriptionId, stripePaymentIntentId);
      }
    } else {
      ManageErrorLog(paymentIntent, 'Error Validating Stripe Payment Intent');
      return false;
    }
  }
}
export async function cancelSubscription(userId: string, subscriptionId: string) {
  try {
    const body = {
      user_id: userId,
      user_subscription_id: subscriptionId
    };
    await axios.delete(`${url}/subscriptions/user/cancel`, { data: body });

    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in cancelSubscription');
    return false;
  }
}
