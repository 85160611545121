import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import ModelCard from '../../components/ModelCard';
import { Model } from '../../models/interfaces';

export default function ModelRelatedModelsView({ similarModels }: { similarModels: Model[] }) {
  return (
    <div className="m-8 w-full shadow-lg border-t-2 rounded py-4">
      <h1 className="text-2xl font-semibold m-4">Modelos relacionados</h1>
      <ScrollMenu scrollContainerClassName="light_scroll">
        {similarModels.map((model) => (
          <div key={model.model_id} className="m-4">
            <ModelCard
              title={model.name}
              price={model.price}
              brand={model.brand}
              modelId={model.model_id}
              imgBucketPath={model.imgs_bucket_paths[0].path}
              model={model}></ModelCard>
          </div>
        ))}
      </ScrollMenu>
    </div>
  );
}
