import React from 'react';
import ImageCarousel from '../screens/Model/ImageCarousel';
import ImageLigthbox from './ImageLigthbox';

export default function ModelImages({ imgsURLs }: { imgsURLs: string[] }) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);

  return (
    <div className="md:max-w-[40%] md:w-[40%] mx-8 flex justify-center md:mb-0 mb-10">
      {/* Images List */}
      <div className="w-[10%] mr-7 h-auto">
        {imgsURLs.map((imgURL, i) => (
          <img
            className="w-full h-[60px] object-cover mb-2 cursor-pointer"
            src={imgURL}
            key={i}
            onClick={() => {
              setCurrentSlide(i);
            }}
          />
        ))}
      </div>
      {/* Active image */}
      <div className="w-[75%]" onClick={() => setIsLightboxOpen(true)}>
        <ImageCarousel imgsURLs={imgsURLs} currentSlide={currentSlide} />
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <ImageLigthbox
          imgSrc={imgsURLs[currentSlide]}
          setIsOpen={setIsLightboxOpen}
          setCurrentSlide={setCurrentSlide}
          totalImages={imgsURLs.length - 1}
        />
      )}
    </div>
  );
}
