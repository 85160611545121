import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ImageLigthbox({
  imgSrc,
  setIsOpen,
  setCurrentSlide,
  totalImages
}: {
  imgSrc: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
  totalImages: number;
}) {
  return (
    <div className="w-[100%] h-[100vh] top-0 left-0 bg-black/90 z-50 fixed flex justify-center items-center">
      {/* Image container */}
      <div className="w-[90%] h-[90%]">
        <img src={imgSrc} alt="" className="object-contain w-full h-full" />
      </div>

      {/* Close btn */}
      <IconButton
        sx={{ position: 'absolute', top: '10px', right: '10px', color: 'crimson' }}
        onClick={() => {
          setIsOpen(false);
        }}>
        <CloseIcon />
      </IconButton>

      <IconButton
        sx={{ position: 'absolute', top: '50%', left: '10px', color: 'white', opacity: '0.8' }}
        onClick={() => {
          setCurrentSlide((i) => (i === 0 ? 0 : i - 1));
        }}>
        <ArrowBackIosIcon />
      </IconButton>

      <IconButton
        sx={{ position: 'absolute', top: '50%', right: '10px', color: 'white', opacity: '0.8' }}
        onClick={() => {
          setCurrentSlide((i) => (i === totalImages ? i : i + 1));
        }}>
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
}
