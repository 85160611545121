export const divs = {
  grid: 'grid grid-cols-[repeat(auto-fill,300px)] flex-col justify-center',
  content:
    'rounded-lg sm:shadow-xl max-w-[500px] relative mx-auto mt-10 py-2 mb-[50px] justify-self-center'
};

export const buttons = {
  primaryButton: 'shadow rounded  w-full mb-10 p-2 bg-primary',
  disabledButton: 'shadow rounded  w-full mb-10 p-2 bg-gray-300'
};

export const texts = {
  primaryButonText: 'text-white font-semibold',
  title: 'text-3xl text-center my-4 font-semibold text-primary',
  subtitle: 'text-xl text-center my-3  text-gray-600'
};

export const inputs = {
  primaryInput: 'rounded border-2 w-full p-2 mb-4'
};
