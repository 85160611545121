import { buttons, texts } from '../constants/styles';

export default function PageNotFound() {
  return (
    <div>
      <h1 className=" my-[35%] md:my-[5%] md:text-[30px] text-center">
        La página que estas buscando no existe
      </h1>
      <div className="w-full flex flex-col items-center">
        <img className="w-[80%] md:w-[30%] mt-[5%]" src="/imgs/404.svg" alt="No Hay Resultados" />
        <div className="mt-[5%]">
          <button
            onClick={() => {
              window.location.href = '/';
            }}
            className={buttons.primaryButton}>
            <p className={texts.primaryButonText}>Ir a Inicio</p>
          </button>
        </div>
      </div>
    </div>
  );
}
