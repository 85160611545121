import { Button } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { toast, ToastContainer } from 'react-toastify';
import { deleteReview } from '../../apis/reviews';
import ViewImages from '../../components/ViewImages';
import { Model, Review } from '../../models/interfaces';
import { AuthContext } from '../../providers/Auth';
import { TimestampStringToDateString } from '../../utils/dateConverter';
import ImagePathConverter from '../../utils/imagePathConverter';
import { RandomID } from '../../utils/randomIDGenerator';

export default function ModelReviewsView({
  reviewsProps,
  model
}: {
  reviewsProps: Review[];
  model: Model | null;
}) {
  const [reviews, setReviews] = useState(reviewsProps || []);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [reviewsAverage, setReviewsAverage] = useState(0);
  const { currentUser, currentUserData } = useContext(AuthContext);
  const [userHasReviewed, setUserHasReviewed] = useState<boolean>(false);

  const modelId = useParams().nombre;
  const navigate = useNavigate();

  useEffect(() => {
    checkIfUserHasReviewed(reviewsProps);
    setReviews(reviewsProps);
    setReviewsCount(reviewsProps.length);
  }, [reviewsProps]);

  useEffect(() => {
    setTotalReviewsAverage();
  }, [reviews]);

  function setTotalReviewsAverage() {
    let reviewTotalCount = 0;
    reviews.forEach((review) => {
      reviewTotalCount += review.score;
    });
    setReviewsAverage(reviewTotalCount / reviewsCount);
  }

  function checkIfUserHasReviewed(reviews: Review[]) {
    reviews.forEach((review: Review) => {
      if (review.user_id !== undefined && review.user_id === currentUser.uid) {
        setUserHasReviewed(true);
      }
    });
  }

  async function deleteReviewHandler(modelId: string, reviewId: string) {
    // Delete from db
    const res = await deleteReview(modelId, reviewId, currentUser.uid);

    if (!res) {
      toast.error('Hubo un error eliminando la reseña', { position: 'top-center' });
      return;
    } else {
      // Delete from state
      setReviews(reviews.filter((r: any) => r?.review_id !== reviewId));

      toast.success('Reseña eliminada', { position: 'top-center' });
      return;
    }
  }

  return (
    <div className="w-full p-4">
      <div className="shadow-lg border-t-2 rounded py-2">
        {headerSection()}
        {addReviewIfUserRentedClothe()}
        {allReviews()}
      </div>
    </div>
  );

  function headerSection() {
    return (
      <div className="flex">
        <h1 className="text-2xl font-semibold m-4">Reseñas</h1>
        <div className="flex py-4 pl-4">
          <Rating
            size={25}
            SVGclassName="inline-block"
            readonly
            initialValue={reviewsAverage}
            allowFraction
          />
          <p className="pl-1 py-1">{'(' + reviewsCount + ')'}</p>
        </div>
      </div>
    );
  }

  function addReviewIfUserRentedClothe() {
    return (
      currentUserData?.ordered_models?.find((m: string) => m === modelId) &&
      !userHasReviewed && (
        <div className="flex justify-end mx-7 mb-4">
          <Button
            color="success"
            variant="outlined"
            onClick={() => {
              navigate(`/modelo/${modelId}}/agregarResena`, {
                state: model
              });
            }}>
            Añadir Reseña
          </Button>
        </div>
      )
    );
  }

  function allReviews() {
    return reviews.length ? (
      reviews.map((review: Review) => {
        return (
          <ReviewItem
            key={RandomID()}
            review={review}
            deleteReviewHandler={deleteReviewHandler}></ReviewItem>
        );
      })
    ) : (
      <div className="w-full flex justify-center items-center mb-10">
        <p className="text-gray-400">No hay reseñas</p>
      </div>
    );
  }

  interface ReviewItemInterface {
    review: Review;
    deleteReviewHandler: (modelId: string, reviewId: string) => void;
  }

  function ReviewItem({ review, deleteReviewHandler }: ReviewItemInterface) {
    const [imageURL, setImageURL] = useState<string>('');
    const [reviewImgs, setReviewImages] = useState<string[]>([]);

    useEffect(() => {
      getUserImage();
      getReviewImages();
    }, []);

    function getUserImage() {
      if (review.user_img_path !== null) {
        ImagePathConverter(review.user_img_path).then((imgUrl) => {
          setImageURL(imgUrl);
        });
      }
    }
    async function getReviewImages() {
      if (review?.media_paths?.length > 0) {
        const promises = review?.media_paths.map((path: string) => ImagePathConverter(path));
        const responses = await Promise.all(promises);
        setReviewImages(responses);
      }
    }

    //console.log(review, currentUser?.uid, review?.user_id === currentUser?.uid);
    return (
      <div className="mb-12">
        <div className="flex">
          {imageURL !== '' ? (
            <img
              className=" m-4 rounded-full w-[50px] h-[40px] md:h-[50px] bg-green-600"
              src={imageURL}></img>
          ) : (
            <div className=" m-4 rounded-full w-[50px] h-[40px] md:h-[50px] bg-green-600"></div>
          )}

          <div className="w-full">
            <div className="w-full flex">
              <p className="text-xl font-semibold">{review.title}</p>

              {review?.user_id === currentUser?.uid && currentUser?.uid !== undefined && (
                <Button
                  sx={{ ml: 5 }}
                  color="error"
                  size="small"
                  variant="outlined"
                  onClick={() => deleteReviewHandler(review?.model_id, review?.review_id)}>
                  Eliminar Reseña
                </Button>
              )}
            </div>
            <p className=" text-gray-500">{TimestampStringToDateString(review.created_at)}</p>

            <Rating
              size={25}
              SVGclassName="inline-block"
              readonly
              initialValue={review.score}
              allowFraction
            />

            <p>{review.content}</p>

            {/* Media */}
            <div>
              <ViewImages imgs={reviewImgs}></ViewImages>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}
