import Bugsnag from '@bugsnag/js';

export default function ManageErrorLog(error: unknown, errorClass: string) {
  const isProd = process.env.REACT_APP_IS_PROD || false;

  if (isProd === 'false' || !isProd) {
    console.log(errorClass);
    console.log(error);
  } else {
    if (error instanceof Error) {
      Bugsnag.notify({ errorClass: errorClass, errorMessage: error.message });
    }
  }
}
