import { TopBar } from '../../components/Topbar';
import Footer from './Footer';

export default function TopBarScreen({
  children,
  backRoute
}: {
  children: React.ReactNode;
  backRoute?: string;
}) {
  return (
    <div className="max-w-[1920px]">
      <TopBar backRoute={backRoute}></TopBar>
      <div className="min-h-[70vh]">{children}</div>
      <Footer />
    </div>
  );
}
