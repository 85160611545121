import axios from 'axios';
import { Model, Review } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function getModels(pageDocs: number, docNameRef?: string, nextPage?: boolean) {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    let responseURL = `${url}/clothes?pageDocs=${pageDocs}&filterAvailableClothes=true`;

    if (nextPage !== undefined && docNameRef !== undefined) {
      if (nextPage) {
        responseURL += `&pageAction=next&docNameRef=${docNameRef}`;
      } else {
        responseURL += `&pageAction=prev&docNameRef=${docNameRef}`;
      }
    }

    const response = await axios.get(responseURL);
    const clothes = response.data.clothes;
    return clothes;
    //return filterAvailableProducts(clothes);
  } catch (error) {
    ManageErrorLog(error, 'Error in getModels');
    return [];
  }
}

export const getModelsByName = async (value: string) => {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(
      `${url}/clothes/search?query=${value}&filterAvailableClothes=true`
    );
    return response.data.models;
  } catch (error) {
    ManageErrorLog(error, 'Error in getModelsByName');
    return [];
  }
};

export const getModelByName = async (id: string) => {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(`${url}/clothes/${id}`);
    return response.data.model;
  } catch (error) {
    ManageErrorLog(error, 'Error in getModelByName');
    return false;
  }
};

export async function getModelReviews(id: string, userId: string) {
  try {
    const response = await axios.get(`${url}/reviews/${id}?userId=${userId}`);
    return response.data.reviews as Review[];
  } catch (error) {
    ManageErrorLog(error, 'Error in getModelReviews');
    return [];
  }
}

export async function putEmailOnAwaitList(modelId: string, userEmail: string, userId: string) {
  try {
    await axios.put(`${url}/clothes/model/emails-to-notify`, {
      modelId: modelId,
      userEmail: userEmail,
      securityID: userId
    });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in putEmailOnAwaitList');
    return false;
  }
}

export async function isEmailInAwaitingList(modelId: string, userEmail: string, userId: string) {
  try {
    const result = await axios.post(`${url}/clothes/model/is_email_in_list`, {
      modelId: modelId,
      userEmail: userEmail,
      securityID: userId
    });
    return result.data.data;
  } catch (error) {
    ManageErrorLog(error, 'Error in putEmailOnAwaitList');
    return false;
  }
}
