import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCategoryById, getModelsByCategory } from '../../apis/categories';
import { getModelByName, getModelReviews } from '../../apis/models';
import { getAllSubcategories } from '../../apis/subcategories';
import LoadingWidget from '../../components/LoadingWidget';
import { Model, Review } from '../../models/interfaces';
import TopBarScreen from '../layouts/TopBarScreen';
import ModelButtonsView from './ModelButtonsView';
import ModelInfoView from './ModelInfoView';
import ModelRelatedModelsView from './ModelRelatedModelsView';
import ModelReviewsView from './ModelReviewsView';
import ModelImages from '../../components/ModelImages';
import { AuthContext } from '../../providers/Auth';

export default function ModelScreen() {
  const { nombre } = useParams();
  const [model, setModel] = useState<Model | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sizesKeys, setSizesKeys] = useState<string[]>([]);
  const [subcategories, setSubcategories] = useState<Map<string, string>>();
  const [category, setCategory] = useState<string>('');
  const [imgsURLs, setImgsUrls] = useState<string[]>([]);
  const [checked, setChecked] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [similarModels, setSimilarModels] = useState<Model[]>([]);

  useEffect(() => {
    getSubcategoriesFromModel();
    getModelData();
  }, []);

  function getSubcategoriesFromModel() {
    getAllSubcategories().then((response) => {
      const subcategoriesMap = new Map<string, string>();
      response.forEach((v: { id: string; name: string }) => {
        subcategoriesMap.set(v.id, v.name);
      });
      setSubcategories(subcategoriesMap);
    });
  }
  function getModelData() {
    getModelByName(nombre || '').then((modelResponse) => {
      setModel(modelResponse);

      //after getting the model, get the sizes, I saved them in a map, so I can get the keys
      const sizesMap = new Map<string, number>(Object.entries(modelResponse.sizes));
      const sizesKeyHolder: string[] = [];

      sizesMap.forEach((v, k) => {
        if (Number(v) >= 1) {
          sizesKeyHolder.push(k);
        }
      });

      setSizesKeys(sizesKeyHolder);
    });
  }

  useEffect(() => {
    if (typeof model !== 'undefined' && model !== null && !checked) {
      getModelCategory();
      setImageURLs();
      getModelReviewsFromAPI();
      getSimilarModels();

      setChecked(true);
      setIsLoading(false);
    }
  }, [model]);

  function getModelCategory() {
    getCategoryById(model?.category_id || '').then((categoryResponse) => {
      if (typeof categoryResponse === 'string') {
        setCategory(categoryResponse);
      }
    });
  }
  function setImageURLs() {
    const newImgURLs: string[] = [];
    model?.imgs_bucket_paths.forEach((imgPath) => {
      const imageReference = ref(getStorage(), imgPath.path);
      getDownloadURL(imageReference).then((imageUrl) => {
        newImgURLs.push(imageUrl);
      });

      setImgsUrls(newImgURLs);
    });
  }
  function getModelReviewsFromAPI() {
    getModelReviews(model?.model_id || '', currentUser?.uid).then((reviewsD) => {
      setReviews(reviewsD);
    });
  }
  function getSimilarModels() {
    getModelsByCategory(model?.category_id || '').then((modelsResponse) => {
      const models: Model[] = [];
      const modelsResponseMap = modelsResponse.models;
      const newMap = new Map<string, Model[]>();

      for (const modelKeys in modelsResponseMap) {
        newMap.set(modelKeys, modelsResponseMap[modelKeys]);
      }

      // The similar models reponse is returning a map with the subcategory and the models. I am looping
      // through the map and getting the models and pushing them to the models array
      const array = Array.from(newMap, ([_, value]) => value);

      let counter = 0;
      array.forEach((modelArray) => {
        modelArray.forEach((modelA) => {
          if (counter <= 10 && modelA.model_id !== model?.model_id) {
            let available = false;
            const sizes = modelA.sizes ?? [];
            Object.values(sizes).forEach((val: any) => {
              if (Number(val) >= 1) {
                available = true;
              }
            });
            if (available) {
              models.find((m) => m.model_id === modelA.model_id) || models.push(modelA);
            }
          }
          counter++;
        });
      });
      setSimilarModels(models);
    });
  }

  return (
    <TopBarScreen>
      {isLoading ? (
        <div className="h-[100px] w-[100px] relative mx-auto mt-[25%]">
          <LoadingWidget></LoadingWidget>
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col bg:flex-row">
          <div className="flex flex-col md:flex-row mt-10 w-full">
            <ModelImages imgsURLs={imgsURLs} />
            <ModelInfoView
              sizesKeys={sizesKeys}
              category={category}
              subcategories={subcategories}
              model={model}></ModelInfoView>
          </div>
          {model && <ModelButtonsView model={model}></ModelButtonsView>}
          {similarModels.length > 0 && (
            <ModelRelatedModelsView similarModels={similarModels}></ModelRelatedModelsView>
          )}
          <ModelReviewsView reviewsProps={reviews} model={model}></ModelReviewsView>
        </div>
      )}
    </TopBarScreen>
  );
}
