import { Stripe, loadStripe } from '@stripe/stripe-js';
const stripeSK = process.env.REACT_APP_STRIPE_SK || '';

let stripePromise: Promise<Stripe | null>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeSK);
  }
  return stripePromise;
};
