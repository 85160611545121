export function getMembershipStatus(status: string | undefined) {
  switch (status) {
    case 'active':
      return 'Activa';
    case 'canceled':
      return 'Cancelada';
    case 'preordered':
      return 'Inicia el siguiente periodo';
    default:
      return '-';
  }
}

export function getMembershipDate(ms: number | undefined) {
  if (ms) return new Date(ms * 1000);
  return new Date();
}
