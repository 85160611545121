import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DashboardAndTopbarScreen from '../layouts/DashboardAndTopbarScreen';
import { toast, ToastContainer } from 'react-toastify';
import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth';
import { getUser, isEmailAndPhoneNumberRelated } from '../../apis/users';
import { auth } from '../../firebaseConfig';
import { AuthContext } from '../../providers/Auth';

export default function Login() {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('');
  const [stepVerifyOTP, setStepVerifyOTP] = useState(false);
  const [disableFirstButton, setDisableFirstButton] = useState(false);
  const [disableSecondButton, setDisableSecondButton] = useState(false);
  const { setCurrentUser, setCurrentUserData } = React.useContext(AuthContext);
  const otpInputRef = useRef(null);
  const navigate = useNavigate();

  async function handleLogin() {
    const emailAndPhoneNumberRelated = await isEmailAndPhoneNumberRelated(email, phoneNumber);
    if (emailAndPhoneNumberRelated !== false) {
      await requestOTP();
    } else {
      toast.error('El email y el número proporcionado son incorrectos', {
        position: 'top-center'
      });
    }
  }

  async function requestOTP() {
    // Disable button
    setDisableFirstButton(true);

    // Recaptcha
    generateReCaptha();

    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success('Se ha enviado un código a tu telefono', { position: 'top-center' });
        setStepVerifyOTP(true);

        // Scroll and focus OTP input
        setTimeout(() => {
          // @ts-ignore
          otpInputRef?.current?.focus();
          // @ts-ignore
          otpInputRef?.current?.scrollIntoView();
          window.scrollBy(0, -160);
        }, 200);
      })
      .catch((error) => {
        setDisableFirstButton(false);
        toast.error(
          'Has intentado más de una vez, o el número es inválido. Vuelve a cargar la página',
          { position: 'top-center' }
        );
        console.log(error);
      });
  }

  function generateReCaptha() {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'reCaptachaContainer',
      {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      },
      auth
    );
  }

  function verifyOTP() {
    setDisableSecondButton(true);

    window.confirmationResult
      .confirm(OTP)
      .then(async (result: any) => {
        // Return an existing user, or will create a new one
        const user = result.user;

        if (!user || !user.uid) {
          toast.error('No se ha encontrado una cuenta con el número proporcionado', {
            position: 'top-center'
          });
          setCurrentUser(null);
          setCurrentUserData(null);
          auth.signOut();
          return;
        }

        // Get user data from database and verify same email
        const resUser = await getUser(user?.uid);
        if (!resUser?.data) {
          toast.error('No se ha encontrado una cuenta con el número proporcionado', {
            position: 'top-center'
          });
          setCurrentUser(null);
          setCurrentUserData(null);
          auth.signOut();
        }

        const userData = resUser.data;
        if (userData.email !== email) {
          toast.error('El correo electrónico no coincide con el número de teléfono', {
            position: 'top-center'
          });
          setCurrentUser(null);
          setCurrentUserData(null);
          auth.signOut();
          return;
        }

        // Success
        toast.success('Sesión iniciada correctamente', { position: 'top-center' });
        setCurrentUser(user);
        setCurrentUserData(userData);
        navigate('/miPerfil');
      })
      .catch((error: any) => {
        console.log('ERROR', error);
        toast.error('El código de verificación es incorrecto', { position: 'top-center' });
        setDisableSecondButton(false);
        // User couldn't sign in (bad verification code?)
      });
  }

  return (
    <DashboardAndTopbarScreen screen="miPerfil">
      <div className="my-[10vh]">
        <div className="rounded-lg shadow-xl  max-w-[500px] relative mx-auto pb-8">
          <p className="text-3xl mb-10 text-center font-bold text-primary">Iniciar Sesión</p>
          <div className="mx-4 mb-6">
            <p className="font-semibold mb-2">Correo Electrónico</p>
            <input
              value={email}
              disabled={stepVerifyOTP}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="rounded border-2 w-full p-2"
              placeholder="antonio@email.com"></input>
          </div>
          <div className="mx-4">
            <p className="font-semibold mb-2">Número Celular</p>
            <input
              value={phoneNumber}
              disabled={stepVerifyOTP}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="rounded border-2 w-full p-2 mb-8"
              type="tel"
              placeholder="+34 3792839283"></input>
          </div>

          {/* OTP input */}
          {stepVerifyOTP && (
            <div className="mx-4" id="otp-input">
              <p className="font-semibold mb-2">Código de Verificación</p>
              <input
                value={OTP}
                ref={otpInputRef}
                onChange={(e) => {
                  setOTP(e.target.value);
                }}
                className="rounded border-2 w-full p-2 mb-8"
                type="tel"
                placeholder="123456"></input>
            </div>
          )}

          {/* First step button: Request OTP */}
          {!stepVerifyOTP ? (
            <div className="mx-4">
              <button
                disabled={email.length < 1 || phoneNumber.length < 1 || disableFirstButton}
                onClick={handleLogin}
                className={
                  'shadow rounded  w-full mb-8 p-2 ' +
                  (!(email.length < 1 || phoneNumber.length < 1) ? ' bg-primary' : ' bg-gray-300')
                }>
                <p className="text-white font-semibold">Continuar</p>
              </button>
            </div>
          ) : (
            // Second step button: Verify OTP
            <div className="mx-4 my-3">
              <button
                disabled={email.length < 1 || phoneNumber.length < 1 || disableSecondButton}
                className={
                  'shadow rounded  w-full mb-8 p-2 ' +
                  (!(email.length < 1 || phoneNumber.length < 1) ? ' bg-primary' : ' bg-gray-300')
                }
                onClick={verifyOTP}>
                <p className="text-white font-semibold">Confirmar Código</p>
              </button>
            </div>
          )}

          <Link to="/registro">
            <p className="mx-5 text-primary underline text-[14px]">¿No tienes cuenta? Registrate</p>
          </Link>
        </div>
        <div id="reCaptachaContainer"></div>
      </div>

      <ToastContainer />
    </DashboardAndTopbarScreen>
  );
}
