/*eslint no-unsafe-optional-chaining: "off"*/
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { updateUser } from '../../../apis/users';
import { divs } from '../../../constants/styles';
import { Address } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import { RandomID } from '../../../utils/randomIDGenerator';

export default function AddressesView() {
  const { currentUser, currentUserData, setCurrentUserData } = useContext(AuthContext);
  const [selectAdressMode] = useState(useLocation().state?.selectAdressMode);
  const nextPath = useLocation().state?.nextPath;

  const [addresses, setAddresses] = useState<Address[]>(currentUserData?.adresses);

  const navigate = useNavigate();
  const isFromCart = selectAdressMode === undefined ? false : true;
  const handleClick = () =>
    navigate(`/miPerfil/direcciones/agregarDireccion`, {
      state: { isFromCart: isFromCart }
    });

  useEffect(() => {
    if (addresses !== currentUserData?.adresses) {
      updateUser({ id: currentUser.uid, user: currentUserData, adresses: addresses }).then((e) => {
        if (e) {
          toast.success('Dirección Eliminada', { position: 'top-center' });
          setCurrentUserData({ ...currentUserData, adresses: addresses });
        } else {
          toast.error('Error al eliminar dirección', { position: 'top-center' });
        }
      });
    }
  }, [addresses]);

  return (
    <div className={divs.content}>
      <h1 className="text-3xl text-center my-4 font-semibold text-primary">Direcciones</h1>
      {addresses.length > 0 ? (
        addresses?.map((address: Address) => {
          return AddressCard(address);
        })
      ) : (
        <p className="text-xl m-8 font-semibold text-red-600">No tienes direcciones registradas</p>
      )}

      {/* When select address mode is active disable add address */}

      <div>
        <FontAwesomeIcon
          onClick={handleClick}
          className="h-[20px] w-[20px] bg-primary rounded-full p-3 text-white ml-[75%] md:ml-[85%]"
          icon={faAdd}></FontAwesomeIcon>
      </div>

      <ToastContainer></ToastContainer>
    </div>
  );

  function AddressCard(address: Address) {
    function selectAddress() {
      navigate(nextPath, {
        state: { selectedAddress: address }
      });
    }

    return (
      <div
        key={RandomID()}
        className="bg-[#F8F6F8] rounded m-4"
        onClick={selectAdressMode ? selectAddress : () => {}}>
        <div className=" mx-4 mt-6 p-2 flex place-content-between" key={RandomID()}>
          <div>
            <p className="tF8F6F8">{address.tag}</p>
            <div className="flex">
              <p>{address.street}</p>
              {address.exterior_number && <p className="ml-1">{address.exterior_number}</p>}
              {address.interior_number && (
                <div className="flex">
                  <p>,</p>
                  <p className="ml-1">{address.interior_number}</p>
                </div>
              )}
            </div>
          </div>

          {/* When select address mode is active disable add edit and delete */}
          {!selectAdressMode && (
            <div>
              <FontAwesomeIcon
                onClick={() => {
                  navigate(`/miPerfil/direcciones/direccion`, { state: address });
                }}
                className="text-primary text-2xl m-4"
                icon={faEdit}></FontAwesomeIcon>
              <FontAwesomeIcon
                onClick={() => {
                  setAddresses(addresses.filter((a: Address) => a !== address));
                }}
                className="text-red-600 text-2xl m-4"
                icon={faTrash}></FontAwesomeIcon>
            </div>
          )}
        </div>
        <img className="mb-6 w-full m-[11px] " src="/imgs/map.png"></img>
      </div>
    );
  }
}
