import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { addAddress } from '../../../apis/addresses';
import { buttons, divs, inputs, texts } from '../../../constants/styles';
import { Address } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AddAddressView() {
  const [street, setStreet] = useState<string>('');
  const [exteriorNumber, setExteriorNumber] = useState<string>('');
  const [interiorNumber, setInteriorNumber] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [tag, setTag] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isFromCart] = useState(useLocation().state?.isFromCart);
  const { currentUser, setCurrentUserData, currentUserData } = useContext(AuthContext);

  const address = {
    street: street,
    exterior_number: exteriorNumber,
    interior_number: interiorNumber,
    postal_code: postalCode,
    tag: tag,
    description: description
  } as Address;
  const navigate = useNavigate();
  const handleClick = () => navigate(`/carrito`, { state: { selectedAddress: address } });
  function addAddressToFB() {
    const address = {
      street: street,
      exterior_number: exteriorNumber,
      interior_number: interiorNumber,
      postal_code: postalCode,
      tag: tag,
      description: description
    } as Address;

    addAddress(currentUser.uid, address).then((res) => {
      if (!res) {
        toast.error('Error al agregar dirección', { position: 'top-center' });
      } else {
        toast.success('Dirección agregada', { position: 'top-center' });
        setTimeout(() => {
          if (isFromCart) {
            const addresses = currentUserData.adresses;
            addresses.push(address);
            setCurrentUserData({ ...currentUserData, adresses: addresses });
            handleClick();
          } else {
            window.location.href = '/miPerfil/direcciones';
          }
        }, 2000);
      }
    });
  }

  return (
    <TopBarScreen backRoute="../../miPerfil/direcciones">
      <div className={divs.content}>
        <h1 className={texts.title}>Agregar nueva dirección</h1>
        <InputWithHint onChange={setStreet} value={street} hint="Calle"></InputWithHint>
        <InputWithHint
          onChange={setExteriorNumber}
          value={exteriorNumber}
          hint="Número exterior"></InputWithHint>
        <InputWithHint
          onChange={setInteriorNumber}
          value={interiorNumber}
          hint="Número interior"></InputWithHint>
        <InputWithHint
          onChange={setPostalCode}
          value={postalCode}
          hint="Código postal"></InputWithHint>
        <InputWithHint onChange={setTag} value={tag} hint="Tag"></InputWithHint>
        <div className="m-4">
          <p className="text-lg pl-1">{'Descrición'}</p>
          <textarea
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            className={inputs.primaryInput}></textarea>
        </div>

        <div className="mx-4">
          <button
            disabled={
              street.length === 0 ||
              exteriorNumber.length === 0 ||
              postalCode.length === 0 ||
              tag.length === 0
            }
            onClick={() => {
              addAddressToFB();
            }}
            className={
              street.length === 0 ||
              exteriorNumber.length === 0 ||
              postalCode.length === 0 ||
              tag.length === 0
                ? buttons.disabledButton
                : buttons.primaryButton
            }>
            <p className={texts.primaryButonText}>Guardar</p>
          </button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </TopBarScreen>
  );
}

function InputWithHint({
  hint,
  value,
  onChange
}: {
  value: string;
  hint: string;
  onChange: Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="m-4">
      <p className="text-lg pl-1">{hint}</p>
      <input
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        className={inputs.primaryInput}></input>
    </div>
  );
}
