import axios from 'axios';
import { Address, User } from '../models/interfaces';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function getUser(id: string) {
  try {
    const response = await axios.get(`${url}/users/getUser/${id}`);
    return response.data;
  } catch (error) {
    ManageErrorLog(error, 'Error in getUser');
    return [];
  }
}

export async function createUser(name: string, email: string, phoneNumber: string, id: string) {
  try {
    await axios.post(`${url}/users/newUser`, {
      email: email,
      phone_number: phoneNumber,
      name: name,
      uid: id
    });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error createUser');
    return false;
  }
}

export interface UpdateUserProps {
  user: User;
  id: string;
  name?: string;
  adresses?: Address[];
  cart?: string[];
  claims_ids?: string[];
  orders_ids?: string[];
  user_img?: string;
  phone_number?: string;
  subscription_id?: string;
  favorites_ids?: string[];
  credits?: number;
  email?: string;
}
export async function updateUser({
  id,
  user,
  name,
  adresses,
  cart,
  claims_ids,
  orders_ids,
  user_img,
  phone_number,
  subscription_id,
  favorites_ids,
  credits,
  email
}: UpdateUserProps) {
  try {
    await axios.put(`${url}/customers/${id}`, {
      name: name || user.name || null,
      adresses: adresses || user.adresses || null,
      cart: cart || user.cart || null,
      claims_ids: claims_ids || user.claims_ids || null,
      orders_ids: orders_ids || user.orders_ids || null,
      user_img: user_img || user.user_img || null,
      phone_number: phone_number || user.phone_number || null,
      subscription_id: subscription_id || user.subscription_id || null,
      favorites_ids: favorites_ids || user.favorites_ids || null,
      credits: credits || user.credits || null,
      email: email || user.email || null
    });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in updateUser');
    return false;
  }
}

export async function updateImage(
  id: string,
  newImgData?: {
    filePath: string | undefined;
    fileName: string | undefined;
  } | null
) {
  try {
    await axios.put(`${url}/users/${id}/image`, { new_img_data: newImgData });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in updateUser');
    return false;
  }
}

export async function deleteUserImage(id: string) {
  try {
    await axios.delete(`${url}/users/${id}/image`, {
      data: { id: id }
    });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in deletingUserImage');
    return false;
  }
}

export async function verifyEmailInUse(email: string) {
  try {
    const response = await axios.get(`${url}/customers/verify-email/${email}`);

    return response.data.emailInUse;
  } catch (error) {
    ManageErrorLog(error, 'Error in verifyEmailInUse');
    return error;
  }
}

export async function addEmailToAwaitingList(email: string) {
  try {
    await axios.put(`${url}/users/awaiting-list/${email}`);
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in addEmailToAwaitingList');
    return false;
  }
}

export interface ContactUsProps {
  contact_method: string;
  contact_value: string;
  message?: string;
  type: string;
}
export async function contactUs({ contact_method, contact_value, message, type }: ContactUsProps) {
  try {
    await axios.put(`${url}/users/contact-us`, {
      contact_method,
      contact_value,
      message: message || undefined,
      type
    });
    return true;
  } catch (error) {
    ManageErrorLog(error, 'Error in contactUs');
    return false;
  }
}

export async function getUserCart(userId: string) {
  try {
    return (await axios.get(`${url}/customers/${userId}/cart`)).data.data;
  } catch (error) {
    ManageErrorLog(error, 'Error in getUserCart');
    return false;
  }
}

export async function isEmailAndPhoneNumberRelated(email: string, phoneNumber: string) {
  try {
    return (await axios.get(`${url}/users/valid-email-and-phone-number/${email}/${phoneNumber}`))
      .data;
  } catch (error) {
    ManageErrorLog(error, 'Error in isEmailAndPhoneNumberRelated');
    return false;
  }
}
