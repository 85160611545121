import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { setOrderInSupport } from '../../../apis/orders';

import { colors } from '../../../constants/colors';
import { Order } from '../../../models/interfaces';

interface SupportDialog {
  order_id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order;
  setToast: (open: boolean) => void;
}

export function SupportDialog({ open, setOpen, order_id, order, setToast }: SupportDialog) {
  const previousData = order.support_data;
  const [contactType, setContactType] = useState<string>(
    previousData?.contact_method || 'Mensaje de texto'
  );

  const isInWorkerSupport = order.is_in_support && order.support_data.created_by == 'worker';

  const [contactValue, setContactValue] = useState<string>(previousData?.contact_value || '');
  const [message, setMessage] = useState<string>(previousData?.message || '');
  const supportData = {
    order_id: order_id,
    support_data: {
      support_status: 'contacting_customer',
      message: message,
      contact_method: contactType,
      contact_value: contactValue,
      created_by: isInWorkerSupport ? 'worker' : 'customer'
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    SendSupportData().then((e: boolean) => {
      setToast(e);
      setOpen(false);
    });
  };

  async function SendSupportData() {
    return await setOrderInSupport(supportData);
  }
  if (!open) return <></>;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="border-primary border-4 ">
          <DialogTitle id="alert-dialog-title">Contacta a Soporte</DialogTitle>
          <DialogContent>
            {isInWorkerSupport && (
              <>
                <p className="text-orange-600 text-xl text-center">
                  Tuvimos que establecer tu pedido en soporte
                </p>
                <p className="pt-4 text-gray-500">Motivo:</p>
                <p className="text-lg pb-4">{message}</p>
              </>
            )}
            <DialogContentText className="py-4" id="alert-dialog-description">
              ¿Cómo quieres que te contactemos?
            </DialogContentText>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contactType}
              label="Age"
              onChange={(e) => {
                setContactType(e.target.value);
              }}>
              <MenuItem value={'Mensaje de texto'}>Mensaje de texto</MenuItem>
              <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem>
              <MenuItem value={'Llamada telefónica'}>Llamada telefónica</MenuItem>
            </Select>

            <DialogContentText className="pt-10 pb-7" id="alert-dialog-description">
              Ingresa el método de contacto.
            </DialogContentText>
            <input
              value={contactValue}
              onChange={(e) => {
                setContactValue(e.target.value);
              }}
              className="bg-grey px-4 py-2 shadow rounded w-[300px]"></input>

            {!isInWorkerSupport && (
              <>
                <DialogContentText className="pt-10 pb-7" id="alert-dialog-description">
                  Describe brevemente cual es el problema.
                </DialogContentText>
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  className="bg-grey px-4 py-2 shadow rounded w-[300px] max-h-[300px] min-h-[150px]"></textarea>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <p className={'text-black'}>Cancelar</p>
            </Button>

            <Button
              disabled={message.length < 1 || contactValue.length < 1}
              onClick={handleAgree}
              autoFocus>
              <p className={colors.primary}>Enviar</p>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
