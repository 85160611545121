import axios from 'axios';
import ManageErrorLog from '../utils/manageErrorLog';

const url = process.env.REACT_APP_SERVER_URL;

export async function getAllSubcategories() {
  // Try getting the data. If it is a bad request, it will return an empty array.
  try {
    const response = await axios.get(`${url}/categories/subcategories`);
    return response.data.subcategories;
  } catch (error) {
    ManageErrorLog(error, 'Error in getAllSubcategories');
    return [];
  }
}
export async function getSubcategoryModelsByName(value: string, subcategory: string) {
  try {
    const response = await axios.get(
      `${url}/clothes/search-by-subcategory?query=${value}&subcategory=${subcategory}&filterAvailableClothes=true`
    );
    return response.data.models;
  } catch (error) {
    ManageErrorLog(error, 'Error in getSubcategoryModelsByName');
    return [];
  }
}

export async function getAllModelsBySubcategory(subcategory: string) {
  try {
    const response = await axios.get(
      `${url}/clothes/get-all-products-by-subcategory?subcategory=${subcategory}&filterAvailableClothes=true`
    );
    return response.data.models;
  } catch (error) {
    ManageErrorLog(error, 'Error in getAllModelsBySubcategory');
    return [];
  }
}
