import React, { useContext, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { AuthContext } from '../providers/Auth';
import ImagePathConverter from '../utils/imagePathConverter';
import IconButton from '@mui/material/IconButton';
export const TopBar = ({
  openSideBar,
  backRoute
}: {
  openSideBar?(flag: boolean): void;
  backRoute?: string;
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [imageURL, setImageURL] = useState<string>(''); // [1
  const { currentUserData } = useContext(AuthContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentUserData?.user_img) {
      ImagePathConverter(currentUserData?.user_img).then((imgUrl) => {
        setImageURL(imgUrl);
      });
    } else {
      setImageURL('/imgs/no-user.jpg');
    }
  }, []);

  return (
    <div className="sticky top-0 z-30 flex-shrink-0 flex h-[70px] bg-white shadow-[0px_4px_10px_rgba(0,0,0,0.05)] items-center">
      {openSideBar !== undefined ? (
        <button
          onClick={() => openSideBar(true)}
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
          <span className="sr-only">Open sidebar</span>

          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </button>
      ) : (
        <IconButton
          sx={{ ml: 3 }}
          onClick={() => {
            if (backRoute) navigate(backRoute);
            else navigate(-1);
          }}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <div className="flex-1 px-4 flex justify-between items-center">
        <div className="flex justify-center">
          <Link to="/home">
            <img src="/imgs/logo.png" alt="Logo" className="w-[120px] ml-3 mt-[5px]" />
          </Link>
        </div>

        <div className="ml-4 flex items-center md:ml-6">
          <div
            className="ml-3 flex items-center relative cursor-pointer"
            onClick={() => {
              if (currentUserData !== undefined && currentUserData !== null) {
                setShowSettings(!showSettings);
              } else {
                navigate('/ingresar');
              }
            }}>
            <div>
              <button
                className={'rounded-full bg-lightPurple px-[7px] py-[5px] mr-4 sm:block hidden'}
                type="button"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true">
                <p className="text-center text-principal">
                  {currentUserData !== undefined && currentUserData !== null
                    ? currentUserData?.name
                    : 'Inicia Sesión'}
                </p>
              </button>
            </div>
            <img src={imageURL} alt="Usuario" className="w-[43px] h-[43px] rounded-full mr-3" />
          </div>
        </div>
      </div>
      {showSettings ? (
        <div>
          <div className="w-[224px] shadow-md rounded absolute top-[50px] right-0 mr-[15px] sm:mr-[50px] bg-white border-current">
            <p
              onClick={() => {
                auth.signOut().then(() => {
                  window.location.href = '../miPerfil';
                });
              }}
              className="py-[8px] pl-[16px] font-light hover:bg-[#F3F4F6] cursor-pointer">
              Cerrar Sesión
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};
