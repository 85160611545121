import TopBarScreen from '../../../screens/layouts/TopBarScreen';
import Cards, { Focused } from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useContext, useEffect, useState } from 'react';
import { buttons, divs, inputs, texts } from '../../../constants/styles';
import { getUserPaymentCards, updatePaymentCard } from '../../../apis/paymentCards';
import { StripePaymentCard } from '../../../models/interfaces';
import { AuthContext } from '../../../providers/Auth';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../LoadingScreen';
export default function UpdatePaymentCard() {
  //Required values to create a card
  const { currentUserData } = useContext(AuthContext);

  //Optional values to create a card
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [tag, setTag] = useState('');

  //Fields handlers and design control
  const [validValues, setValidValues] = useState(false);
  const [focus, setFocus] = useState('' as Focused);

  const [card, setCard] = useState({} as StripePaymentCard);
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => navigate(`../miPerfil/metodosDePago`);

  useEffect(() => {
    getUserPaymentCards(currentUserData.email).then((res) => {
      if (res) {
        const card = res.find((card) => card.id === id);
        if (card) {
          setCard(card);
          setLoading(false);
        } else {
          setLoading(false);
          setNotFound(true);
        }
      } else {
        setNotFound(true);
        setLoading(false);
      }
    });
  }, []);

  //Fields validation to create a card
  useEffect(() => {
    if (
      city.length > 0 &&
      postalCode.length > 0 &&
      address.length > 0 &&
      state.length > 0 &&
      tag.length > 0
    ) {
      setValidValues(true);
    } else {
      setValidValues(false);
    }
  }, [name, city, postalCode, address, state, tag]);

  function updateCard() {
    updatePaymentCard({
      name: name,
      paymentMethodId: id || '',
      address: {
        city: city,
        line1: address,

        postal_code: postalCode,
        state: state
      },
      tag: tag
    }).then((res2) => {
      if (res2) {
        toast.success('Tarjeta actualizada con éxito', { position: 'top-center' });
        setTimeout(() => {
          window.location.href = '/miPerfil/metodosDePago';
        }, 3000);
      } else {
        toast.error('Ha ocurrido un error. Verifica que los datos de la tarjeta sean correctos.', {
          position: 'top-center'
        });
      }
    });
  }

  return (
    <TopBarScreen backRoute="../miPerfil/metodosDePago">
      {loading ? (
        <LoadingScreen></LoadingScreen>
      ) : notFound ? (
        <div>404</div>
      ) : (
        <>
          <ToastContainer></ToastContainer>
          <div className={divs.content + ' '} id="PaymentForm">
            <Cards
              preview
              cvc={'***'}
              expiry={card.card.exp_month + card.card.exp_year}
              name={name}
              issuer={card.card.brand}
              number={'************' + card.card.last4}
              focused={focus}
            />
            <div className="m-8">
              <div className=" mb-4">
                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Nombre del titular de la tarjeta'}</p>
                  <input
                    onFocus={() => {
                      setFocus('name');
                    }}
                    className={inputs.primaryInput}
                    value={name}
                    placeholder={'Nombre del titular de la tarjeta'}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </>
                <p className="text-xl my-4">Dirección de la tarjeta</p>
                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Calle y número'}</p>
                  <input
                    className={inputs.primaryInput}
                    value={address}
                    placeholder={'Calle y número'}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </>
                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Ciudad'}</p>
                  <input
                    className={inputs.primaryInput}
                    value={city}
                    placeholder={'Ciudad'}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </>
                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Código Postal'}</p>
                  <input
                    className={inputs.primaryInput}
                    value={postalCode}
                    placeholder={'Código Postal'}
                    onChange={(e) => {
                      setPostalCode(e.target.value);
                    }}
                  />
                </>
                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Estado'}</p>
                  <input
                    className={inputs.primaryInput}
                    value={state}
                    placeholder={'Estado'}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                </>

                <>
                  <p className="text-gray-600 ml-1 mb-2">{'Tag'}</p>
                  <input
                    onFocus={() => {
                      setFocus('name');
                    }}
                    className={inputs.primaryInput}
                    value={tag}
                    placeholder={'Descripción de la dirección'}
                    onChange={(e) => {
                      setTag(e.target.value);
                    }}
                  />
                </>
              </div>
              <div className="flex">
                <button
                  className="shadow rounded  w-full mb-10 p-2 border-2 border-primary flex-1"
                  onClick={handleClick}>
                  <p>Cancelar</p>
                </button>
                <div className="w-[20px]"></div>

                {validValues ? (
                  <button
                    onClick={() => {
                      updateCard();
                    }}
                    className={buttons.primaryButton + ' flex-2'}>
                    <p className={texts.primaryButonText}>Guardar tarjeta</p>
                  </button>
                ) : (
                  <button className={buttons.disabledButton + ' flex-2'}>
                    <p className={texts.primaryButonText}>Guardar tarjeta</p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </TopBarScreen>
  );
}
