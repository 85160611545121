import { createTheme } from '@mui/material/styles';

// Personalized material ui theme
export const theme = createTheme({
  palette: {
    primary: {
      main: '#2e7d32'
    }
  }
});
